import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { ContextProvider } from './context/index';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import addToPrototype from './utils/addToPrototype';

addToPrototype();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ContextProvider>
      <App />
      </ContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorkerRegistration.register({
  onUpdate: () => {
    console.log('onUpdate');
  },
  onSuccess: () => {
    console.log('onSuccess');
  },
});