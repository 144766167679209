import { useReducer } from "react";
import { IComandaList, IComandaState } from "../../interfaces/comanda/comanda";
import { comandaContext } from "./ComandaContext";
import { comandaReducer } from "./ComandaReducer";

interface propos {
    children: JSX.Element | JSX.Element[]
}

const INITIAL_STATE: IComandaState = {
    comandas: [],
}

export const ComandaProvider = ({ children }: propos): any => {
    const [comandaState, dispatch] = useReducer(comandaReducer, INITIAL_STATE);

    const setComandas = (comandas: IComandaList[]) => {
        dispatch({
            type: "SET_COMANDAS",
            payload: comandas
        });
    };

    const deleteComanda = (id: string) => {
        dispatch({
            type: "DELETE_COMANDA",
            payload: id
        });
    };

    return (
        <comandaContext.Provider value={{
            comandaState,
            setComandas,
            deleteComanda
        }}>
            {children}
        </comandaContext.Provider>
    )
}
