import { decodeToken } from "react-jwt";
import { IUser } from "../interfaces/user";

type decodedToken = {
    user : IUser;
    token : string;
    exp : number;
}

export function decodeTokenLS(): decodedToken | undefined {
    let token = localStorage.getItem("token");
    if (token) {
        let decode: any = decodeToken(token);
        let { data, exp } = decode;
        let  user: IUser  = data;
        return { user, exp, token };
    }
    return undefined;
}