import { IComandaDetailRequest, IComandaRequest } from "../interfaces/comanda/comanda";
import fetchTimeout from "../utils/fetchTimeout";
import { decodeTokenLS } from "../utils/token";
import { setHeaders, URL_REQUEST } from "./constants";
import { IRequest } from "../interfaces/request";

export async function listarComandas(clienteNombre: string): Promise<IComandaRequest> {


    let decode = decodeTokenLS();
    if (decode) {
        const { localId, empleadoId } = decode.user;
        let data: IComandaRequest;
        let raw = JSON.stringify({
            localId,
            empleadoId,
            clienteNombre
        });
        let res: any = await fetchTimeout(URL_REQUEST + "/api/v1/admin/comanda/mvp/listarComandas", {
            "headers": setHeaders(),
            "body": raw,
            "method": "POST",
        });
        data = await res.json();
        if(res.status === 200){
        return data;
        }
        throw new Error(data.message);
    }
    throw new Error("Token invalido")
}
type TClient = { tipoDocumentoId: number, nroDocumento: string, nombre: string, apellido: string, telefono: string, correo: string, sexo: number, fechaNacimiento: string }
type TPCreateComanda = { cliente: TClient, clienteId: number | null, observaciones: string, detalle: { servicioId: number | null, productoId: number | null, cantidad: number }[] }
export async function createComanda(params: TPCreateComanda): Promise<IRequest> {
    let decode = decodeTokenLS();
    if (decode) {
        const { localId, empleadoId, empresaId } = decode.user;
        const raw = JSON.stringify({
            localId,
            empleadoId,
            empresaId,
            ...params
        });

        try {
            let res: any = await fetchTimeout(URL_REQUEST + "/api/v1/admin/comanda/mvp/crearComanda", {
                "headers": setHeaders(),
                "body": raw,
                "method": "POST",
            });
            return await res.json();
        } catch (error) {
            return { status: "500", message: "Ocurrio un error" }
        }
    }
    return { status: "500", message: "Token invalido" }

}

export async function getComandaDetail(id: number): Promise<IComandaDetailRequest> {

    const res: any = await fetchTimeout(URL_REQUEST + "/api/v1/admin/comanda/mvp/obtenerDetallesComanda/" + id, {
        "headers": setHeaders(),
        "method": "GET",
    });
    let response: IComandaDetailRequest = await res.json();
    if (response.status === "200") {
        response.data = response.data.map((item) => {
            if (item.distritoResidencia === null)
                item.distritoResidencia = "No encontrado";
            if (item.tipoDocumento === null)
                item.tipoDocumento = "No encontrado";
            if (item.nroDocumento === null)
                item.nroDocumento = "No encontrado";
            if (item.clienteNombre === null)
                item.clienteNombre = "No encontrado";
            if (item.servicioId === null)
                item.servicioId = 0;
            if (item.productoId === null)
                item.productoId = 0;

            return item;
        })

        return response;
    }
    throw new Error(response.message);

}
export async function getComandaFinishedDetail(comandaId: number): Promise<IComandaDetailRequest> {
    let decode = decodeTokenLS();
    if (decode) {
        const { empleadoId } = decode.user;
        const raw = JSON.stringify({
            comandaId,
            empleadoId
        });

        try {
            let res: any = await fetchTimeout(URL_REQUEST + "/api/v1/admin/comanda/mvp/obtenerDetallesComandaFinalizada", {
                "headers": setHeaders(),
                "body": raw,
                "method": "POST",
            });
            let response: IComandaDetailRequest = await res.json();
            if (response.status === "200") {
                response.data = response.data.map((item) => {
                    if (item.distritoResidencia === null)
                        item.distritoResidencia = "No encontrado";
                    if (item.tipoDocumento === null)
                        item.tipoDocumento = "No encontrado";
                    if (item.nroDocumento === null)
                        item.nroDocumento = "No encontrado";
                    if (item.clienteNombre === null)
                        item.clienteNombre = "No encontrado";
                    if (item.servicioId === null)
                        item.servicioId = 0;
                    if (item.productoId === null)
                        item.productoId = 0;

                    return item;
                })

                return response;
            }
        } catch (error) {
            throw new Error("Ocurrio un error");

        }
    }
    throw new Error("Token invalido");
}
export async function editComanda(comandaId: number, observations: string, detalle: { servicioId: number | null, productoId: number | null, cantidad: number, detalleId: number | null }[]): Promise<IRequest> {
    let decode = decodeTokenLS();
    if (decode) {
        const { localId, empleadoId, empresaId } = decode.user;
        const raw = JSON.stringify({
            localId,
            empleadoId,
            empresaId,
            comandaId,
            observaciones: observations,
            detalle
        });

        try {
            let res: any = await fetchTimeout(URL_REQUEST + "/api/v1/admin/comanda/mvp/editarComanda", {
                "headers": setHeaders(),
                "body": raw,
                "method": "put",
            });

            return await res.json();
        } catch (error: any) {
            throw new Error(error?.response?.data?.message);
        }
    }
    return { status: "500", message: "Token invalido" }

}