import { IProduct, IProductRequest, IService, IServiceRequest } from "../interfaces/productAndServices";
import fetchTimeout from "../utils/fetchTimeout";
import { decodeTokenLS } from "../utils/token";
import { setHeaders, URL_REQUEST } from "./constants";

export async function getProductsAvaiable(): Promise<IProduct[]> {
    let response: IProductRequest;
    let employeeId;
    const decoded = decodeTokenLS();
    if (decoded) {
        employeeId = decoded.user.empleadoId;

        let res: any = await fetchTimeout(URL_REQUEST + "/api/v1/admin/producto/obtenerProductosPorEmpleado/" + employeeId, {
            "headers": setHeaders(),
            "body": null,
            "method": "GET",
        });
        response = await res.json();
        if (response.status === "200") {
            response.data = response.data.map(prod => ({
                ...prod,
                descripcion: prod.codigo + " - " + prod.descripcion
            }))
            return response.data;
        }
        throw new Error(response.message);
    }
    throw new Error("Token Invalido, por favor vuelva a iniciar sesion");
}

export async function getServicesAvaiable(): Promise<IService[]> {
    let response: IServiceRequest;
    let employeeId;
    const decoded = decodeTokenLS();
    if (decoded) {
        employeeId = decoded.user.empleadoId;

        let res: any = await fetchTimeout(URL_REQUEST + "/api/v1/admin/servicio/obtenerServiciosPorEmpleado/" + employeeId, {
            "headers": setHeaders(),
            "body": null,
            "method": "GET",
        });
        response = await res.json();
        if (response.status === "200") {
            return response.data;
        }
        throw new Error(response.message);
    }
    throw new Error("Token Invalido, por favor vuelva a iniciar sesion");

}
