import Swal from "sweetalert2";
import errorIcon from "../assets/alert-icons/error.svg";
import successIcon from "../assets/alert-icons/success.svg";
import { black_500, dark_violet } from "../components/styled/constant";


type errorProps = {
    message?: string;
    title?: string;
    icon?: "error" | "success";
    confirmButtonText?: string;
    cancelButtonText?: string;
};

export function showPopup({ message, title, icon, confirmButtonText, cancelButtonText }: errorProps) {
    let iconSelected = "";
    if (icon === "error") {
        iconSelected = errorIcon;
    } else if (icon === "success") {
        iconSelected = successIcon;
    }


    return Swal.fire({
        title: title,
        text: message,
        iconHtml: `<img style="width: 9rem;" src='${iconSelected}'/>`,
        customClass: {
            icon: 'no-border',
            confirmButton: !!cancelButtonText? "button-white" : "asd",
        },
        showCancelButton: !!cancelButtonText,
        confirmButtonColor: !!cancelButtonText ? "white" : dark_violet,
        confirmButtonText: confirmButtonText || "OK",
        color: black_500,
        showCloseButton: !!cancelButtonText,
        
        cancelButtonText: cancelButtonText || "Cancel",
        cancelButtonColor: dark_violet,

    });
}
