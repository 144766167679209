
import { URL_REQUEST } from "../request/constants";
import io from 'socket.io-client';
import { decodeTokenLS } from '../utils/token';
import { IComandaList } from '../interfaces/comanda/comanda'; 
export const socket = io(URL_REQUEST,{transports : [ "websocket", "polling" ]});


export function socketInit() {
    const decoded = decodeTokenLS();
    const socketConnect=socket
    if (decoded) {
        socketConnect.emit("join", decoded.user.localId.toString());
        console.log('join SOCKET')
    }
}

export function addNewComanda(mensaje: any, listComandas:IComandaList) {
    const decoded = decodeTokenLS();
    if (decoded) {
        const userId = decoded.user.empleadoId;
        const comanda = mensaje.comanda
        const arrayEmpleados = comanda.map((item: { empleadoId: number; }) => item.empleadoId)
        const handler = arrayEmpleados.includes(userId)
        if (handler && comanda.lenght!==0) {
            let arrayServicios: any[] = [];
            
            comanda.map((item: any) =>
            arrayServicios.push({
                servicio: item.servicio,
                servicioId: item.servicioId,
                productoId: item.productoId,
                cantidad: item.cantidad,
                precio: item.precio,
                fechaCreacion: item.fechaCreacion,
                observaciones: item.observaciones,
            }))
            
            let newComanda = [
                {
                    id: comanda[0].id,
                    empleadoId: comanda[0].empleadoId,
                    empleadosId: mensaje.empleadosId,
                    empleadoAtencion: comanda[0].empleadoAtencion,
                    empleadoCreacion: comanda[0].empleadoCreacion,
                    servicios: arrayServicios,
                    clienteNombre: comanda[0].clienteNombre,
                    clienteApellido: comanda[0].clienteApellido,
                    clienteId: comanda[0].clienteId,
                    observacionesGenerales: comanda[0].observacionesGenerales,
                    nroDocumento: comanda[0].nroDocumento
                }
            ]
            return newComanda

        } else {
            return false
        }
    }
    return false
}

export function refreshComandas(comanda:any){
    const decoded = decodeTokenLS();
    if (decoded) {
        const userId = decoded.user.empleadoId;
        const arrayEmpleados = comanda.map((item: { empleadoId: number; }) => item.empleadoId)
        const handler = arrayEmpleados.includes(userId)
        return handler;
    }
    return false
}


export function deleteRefreshComandas(arrayEmpleados:any){
    const decoded = decodeTokenLS();
    if (decoded) {
        const userId = decoded.user.empleadoId;
        const handler = arrayEmpleados.includes(userId);
        return handler;
    }
    return false
}

