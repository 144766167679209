import './navbar.css';
import { FaTimes, FaPlusSquare, FaSearch } from 'react-icons/fa'
import { IoCartOutline, IoPlayCircleOutline } from "react-icons/io5";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { HiMenuAlt1 } from 'react-icons/hi'
import { NavLink, Outlet, useNavigate, useParams } from "react-router-dom";
import { useCallback, useRef, useState } from 'react';
import { useContext } from 'react';
import { userContext } from '../../context/UserContext/userContext';
import { useEffect } from 'react';
import { useLogin } from '../../hooks/login';
import { searchContext } from '../../context/SearchContext/SearchContext';
import { decodeTokenLS } from '../../utils/token';
import { purple_1 } from '../styled/constant';
import { BsPlusLg } from 'react-icons/bs';
import { StyledCloseButton, StyledparagraphLink } from '../styled/styled';
import useMixpanel from 'hooks/useMixpanel/useMixpanel';

export default function Navbar() {
    const { track } = useMixpanel();
    let navigate = useNavigate();
    let params = useParams();
    const [isOpenNav, setIsOpenNav] = useState(false);
    const [isOpenSearch, setIsOpenSearch] = useState(false);
    const { logout } = useLogin();
    const context = useContext(userContext);
    const { userState } = context;
    const { searchState, setSearch } = useContext(searchContext);
    const { search } = searchState;
    const [nroComanda, setNroComanda] = useState('');
    const searchInputRef = useRef<HTMLInputElement>(null);

    const setTitle = useCallback(() => {
        return (params['*']?.includes('editar') ? 'Comanda' :
            params['*']?.includes('detalle') ? 'Detalles De La Comanda' :
                isOpenSearch ? 'Buscador de comandas' :
                    params['*']?.includes('agregarcomanda') ? 'Comanda' :
                        params['*']?.includes('comanda') ? 'Comandas: ' + (decodeTokenLS()?.user.nombreLocal || '') :
                            params['*']?.includes('coladeservicios') ? 'Cola de servicios' :
                                params['*']?.includes('historialdeservicios') ? 'Historial' :
                                    "") || '';

    }, [params, isOpenSearch]);
    const setSubTitle = useCallback(() => {
        return (isOpenNav ? '' :
            params['*']?.includes('detalle') ? nroComanda :
                isOpenSearch ? "Ingresa el nombre del cliente (minimo 3 para busqueda global)" :
                    params['*']?.includes('agregarcomanda') ? 'Creando comanda' :
                        params['*']?.includes('comanda') ? '' :
                            params['*']?.includes('coladeservicios') ? 'Están atendiendo a' :
                                params['*']?.includes('historialdeservicios') ? 'Mira tu resumen' :
                                    "") || '';

    }, [params, isOpenSearch, isOpenNav, nroComanda]);
    const onCloseSearch = useCallback(() => {
        setIsOpenSearch(false);
        setSearch('');
    }, [setSearch]);


    useEffect(() => {
        function generate(length: number, chars: string) {
            let a = 'N-';
            const total = length - chars.length;
            for (let i = 0; i < total; i++) {
                a += "0";
            }
            return a + chars;
        }
        if (params.id) {
            setNroComanda(generate(8, params.id));
        }
    }, [params.id, setTitle]);

    useEffect(() => {
        if (isOpenSearch && window.location.pathname !== '/comandas') {
            setIsOpenSearch(false);
            setSearch('');
        }
    }, [navigate, isOpenSearch]);// eslint-disable-line

    useEffect(() => {
        if (isOpenSearch)
            searchInputRef.current?.focus();
    }, [isOpenSearch]);

    return <div className='App'>
        <div className="Navbar">
            <div className='Nav' style={params['*']?.includes('editar') ? { background: purple_1 } : {}}>
                <div className='header'>
                    <div className='header-left'>
                        {isOpenNav ?
                            <FaTimes onClick={() => setIsOpenNav(false)} style={{ fontSize: '2rem' }} /> :
                            <HiMenuAlt1 onClick={() => { setIsOpenNav(true); setIsOpenSearch(false); setSearch('') }} style={{ fontSize: '2rem' }} />}
                    </div>
                    <div className='header-center'>
                        <h1>{setTitle()}</h1>
                    </div>
                    <div className='header-right'>
                        {!isOpenSearch ? <FaSearch onClick={() => { track("BuscarC"); navigate('/comandas'); setIsOpenNav(false); setIsOpenSearch(true) }} style={{ height: '2rem', width: '1.7rem', margin: '0 5px 0 5px' }} /> : <StyledCloseButton onClick={onCloseSearch} color="white"><BsPlusLg /></StyledCloseButton>}
                        {isOpenNav && userState.user?.crearComanda && <FaPlusSquare onClick={() => { track("CrearComanda1"); navigate('/comandas/agregarcomanda'); setIsOpenNav(false); }} style={{ fontSize: '2rem', margin: '0 5px 0 5px' }} />}
                    </div>
                </div>
                {setSubTitle() && <div className='subTitles'>
                    {setSubTitle()}
                </div>}
                {isOpenSearch && <input ref={searchInputRef} type='text' style={{ margin: '1rem 2rem' }} placeholder='Buscar' value={search} onChange={(e) => setSearch(e.target.value)} />}
                {(!params['*']?.includes('editar') || isOpenNav) && <div className='border-bottom'></div>}
            </div>
            <div className='body-navbar' style={isOpenNav ? { display: 'flex' } : { display: 'none' }}>
                <NavLink to={'/comandas'} onClick={() => { track("Comandas"); setIsOpenNav(false); }} className='button themedButton navButton'><IoCartOutline style={{ width: '2rem', height: 'auto', padding: '0 5%' }} />Comandas</NavLink>
                <NavLink to={'/coladeservicios'} onClick={() => { track("CServicios"); setIsOpenNav(false); }} className='button themedButton navButton'><IoPlayCircleOutline style={{ width: '2rem', height: 'auto', padding: '0 5%' }} />Cola de servicios</NavLink>
                <NavLink to={'/historialdeservicios'} onClick={() => { track("HServicios"); setIsOpenNav(false); }} className='button themedButton navButton'><AiOutlineMenuUnfold style={{ width: '2rem', height: 'auto', padding: '0 5%' }} />Historial de servicios</NavLink>
                <StyledparagraphLink onClick={() => { setIsOpenNav(false); logout(); }}>Cerrar sesión</StyledparagraphLink>
            </div>
        </div>
        <Outlet />
    </div>;
}