import { userContext } from 'context/UserContext/userContext';
import { IUser } from 'interfaces/user';
import mixpanel from 'mixpanel-browser';
import { useContext } from 'react';

mixpanel.init('de670f873d3694cd2152d99a8791b43a', { debug: false });

type data = {
    user?: IUser
    data?: any
}

export default function useMixpanel() {
    const { userState } = useContext(userContext);
    // const { userState: { user } } = useContext(userContext);
    const track = (event: string, data?: data) => {
        console.log("userState", userState);
        // console.log('track', "Pruebas: " + event, {
        //     user: data?.user || user,
        //     additional_data: data?.data
        // });
        // if (true) {
        //     mixpanel.track("MVP Comandas: " + event, {
        //         user: data?.user || user,
        //         additional_data: data?.data
        //     });
        // }
    }
    return { track };
}