import { IRequest } from "../interfaces/request";
import { IUser, IUserRequest } from "../interfaces/user";
import fetchTimeout from "../utils/fetchTimeout";
import { setHeaders, URL_REQUEST } from "./constants";

export async function loginReq(email: string, clave: string): Promise<IUserRequest> {

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
        email,
        clave
    });

    let requestOptions: RequestInit = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };
    let data: IUserRequest;
    try {
    let res = await fetchTimeout(URL_REQUEST + "/loginEmpleadoComandas", requestOptions, 4000);
    data = await res.json();
    return data;
    } catch (error) {
        return { data: null, status: "500", message: "Ocurrio un error" }
    }
}

export async function recoverPassReq(email: string): Promise<IRequest> {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
        email
    });

    let requestOptions: RequestInit = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    let res = await fetchTimeout(URL_REQUEST + '/recuperarClave', requestOptions, 4000);
    let data: IRequest = await res.json();
    return data;
}

export const recoverDataUser = async () => {

    let requestOptions: RequestInit = {
        method: 'GET',
        headers: setHeaders(),
        redirect: 'follow'
    };

    let res = await fetchTimeout(URL_REQUEST + '/api/v1/empleado', requestOptions, 4000);
    if(res.status === 500 || res.status === 404){
        throw new Error("Ocurrio un error");
    }

    let data: any = await res.json();
    let { data: user }: {data: IUser} = data;
    return user;
}
