import { IComandaList, IComandaState } from "../../interfaces/comanda/comanda";

type ComandaAction =
    | { type: "SET_COMANDAS"; payload: IComandaList[] }
    | { type: "DELETE_COMANDA"; payload: string };


export const comandaReducer = (state: IComandaState, action: ComandaAction): IComandaState => {
    switch (action.type) {
        case "SET_COMANDAS":
            return { ...state, comandas: action.payload };
        case "DELETE_COMANDA":
            return { ...state, comandas: state.comandas.filter(c => c.id !== Number(action.payload)) };
        default:
            return state;
    }
}