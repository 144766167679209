/* eslint-disable array-callback-return */
import { useCallback, useEffect, useState, useContext } from "react";
import { AiFillEdit } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";
import { IComandaDetailRequest } from "../../../interfaces/comanda/comanda";
import { getComandaDetail, getComandaFinishedDetail } from "../../../request/comanda";
import { black_500, dark_purple, magenta_1, purple_1, soft_gray, soft_purple, violet_1 } from "../../../components/styled/constant";
import { BodyContainer, BorderDiv, StyledLabel } from "../../../components/styled/styled";
import { IServices, IClientData } from "../../../interfaces/comanda/comanda";
import { showPopup } from "../../../utils/showAlert";
import { showLoader } from "../../../utils/loader";
import { userContext } from "../../../context/UserContext/userContext";
import useMixpanel from "hooks/useMixpanel/useMixpanel";

export default function Detail() {
    const { track } = useMixpanel()
    const params = useParams();
    const navigate = useNavigate();
    const [services, setServices] = useState<{
        inCourse: IServices[],
        inComing: IServices[],
        finished: IServices[],
        products: IServices[],
    }>({ inCourse: [], inComing: [], finished: [], products: [] });
    const { userState: { user } } = useContext(userContext);

    const [isLoading, setIsLoading] = useState(true);
    const [clientData, setClientData] = useState<IClientData>({ id: 0, docType: "", docNumber: "", name: "", lastName: "", district: "", telefono: "" });
    const [totalPrice, setTotoalPrice] = useState(0);
    const { id, editable } = params

    const initialCharge = useCallback(async (id, editable) => {
        try {
            const response: IComandaDetailRequest = editable === "0" ? await getComandaFinishedDetail(id) : await getComandaDetail(id)
            const handlerInCourse = response.data.filter(service => service.nombreEstado === "EN PROCESO" && service.productoId === 0).map((service): IServices => {
                return {
                    id: service.id,
                    state: service.nombreEstado,
                    price: service.precio,
                    attended: service.empleadoAtencion,
                    name: service.servicio,
                    quantity: service.cantidad
                }
            })
            const handlerInComing = response.data.filter(service => service.nombreEstado === "EN ESPERA" && service.productoId === 0).map((service): IServices => {
                return {
                    id: service.id,
                    state: service.nombreEstado,
                    price: service.precio,
                    attended: service.empleadoAtencion,
                    name: service.servicio,
                    quantity: service.cantidad

                }
            })
            const handlerFinished = response.data.filter(service => service.nombreEstado === "FINALIZADA" && service.productoId === 0).map((service): IServices => {
                return {
                    id: service.id,
                    state: service.nombreEstado,
                    price: service.precio,
                    attended: service.empleadoAtencion,
                    name: service.servicio,
                    quantity: service.cantidad

                }
            })
            const handlerProducts = response.data.filter(service => service.productoId !== 0).map((service): IServices => {
                return {
                    id: service.id,
                    state: service.nombreEstado,
                    price: service.precio,
                    attended: service.empleadoAtencion,
                    name: service.servicio,
                    quantity: service.cantidad
                }
            })
            setServices({ inCourse: handlerInCourse, inComing: handlerInComing, finished: handlerFinished, products: handlerProducts })
            setTotoalPrice(response.data.reduce((acc, curr) => acc + (curr.precio * curr.cantidad), 0))
            console.log()
            setClientData({
                id: response.data[0].clienteId,
                docType: response.data[0].tipoDocumento?.toString() || "",
                docNumber: response.data[0].nroDocumento || "",
                name: response.data[0].clienteNombre,
                lastName: response.data[0].clienteApellido,
                district: response.data[0].distritoResidencia?.toString() || "",
                telefono: response.data[0].clienteTelefono
            })
            setIsLoading(false)
        } catch (error: any) {
            setIsLoading(false)
            console.error(error)
            setTimeout(() => {
                showPopup({
                    message: "Volver a Comandas",
                    icon: "error",
                }).then(() => {
                    navigate("/comandas")
                })
            }, 200)
        }
    }
        , [navigate])

    useEffect(() => {
        if (id) {
            initialCharge(Number(id), editable);
        }
    }, [id, editable, initialCharge])

    useEffect(() => {
        if (isLoading) {
            showLoader();
        }
        else {
            Swal.close();
        }
        return () => {
            Swal.close();
        }
    }, [isLoading])
    
    return (
        <>
            <BodyContainer>
                <Main>
                    <MainContainer>
                        <div style={{ width: '100%' }}>
                            <StyledLabel
                                onClick={() => { navigate("/comandas") }}
                                style={{
                                    color: violet_1,
                                    cursor: 'pointer',
                                    width: 'auto',
                                }}
                            >{"< Regresar"}</StyledLabel>
                        </div>
                        {
                            editable === "0" ?
                                "" :
                                <span style={{ display: "flex", justifyContent: "flex-end", width: '100%' }} ><StyledEditLabel onClick={() => {track("Editar Comanda");navigate('editar')}}> <StyledEditIcon /> Editar Comanda</StyledEditLabel></span>
                        }

                        <BorderDiv>
                            <StyledClientTitle>Datos del cliente</StyledClientTitle>
                            <StyledClientDataMain>
                                {
                                    (user?.tipoPrivacidadDatosCliente !== 3 || user?.empleadoTipoId !== 2) ?
                                        <>
                                            <ClientDataTitle>Tipo de documento</ClientDataTitle>
                                            <ClientDataContent>{clientData.docType}</ClientDataContent>
                                            <ClientDataTitle>N° de documento</ClientDataTitle>
                                            <ClientDataContent>{clientData.docNumber}</ClientDataContent>

                                        </>
                                        :
                                        null
                                }

                                <ClientDataTitle>Nombres y apellidos:</ClientDataTitle>
                                <ClientDataContent>{clientData.name + " " + clientData.lastName}</ClientDataContent>

                                {
                                    (user?.tipoPrivacidadDatosCliente !== 3 || user?.empleadoTipoId !== 2) ?
                                        <>
                                            <ClientDataTitle>N° de telefono</ClientDataTitle>
                                            <ClientDataContent>{clientData.telefono || "No registrado"}</ClientDataContent>
                                        </>
                                        :
                                        null
                                }
                            </StyledClientDataMain>
                        </BorderDiv>
                        {(services.inCourse.length > 0) && <>
                            <StateTitle>Servicios en curso</StateTitle>
                            {services.inCourse.map((service: IServices) => {
                                return (
                                    <InCourseDiv>
                                        <TitleServiceCard><label>{service.name}</label><p>S/{(service.price * service.quantity)}</p></TitleServiceCard>
                                        <AtempededServiceCard color="white">Atendido por: {service.attended}</AtempededServiceCard>
                                    </InCourseDiv>)
                            })}
                        </>
                        }
                        {(services.inComing.length > 0) && <>
                            <StateTitle>Por atender</StateTitle>
                            {services.inComing.map((service: IServices) => {
                                return (
                                    <InComingDiv>
                                        <TitleServiceCard><label>{service.name}</label><p>S/{(service.price * service.quantity)}</p></TitleServiceCard>
                                        <AtempededServiceCard>Atendido por: {service.attended}</AtempededServiceCard>
                                    </InComingDiv>)
                            })}
                        </>}
                        {(services.products.length > 0) && <>
                            <StateTitle>Productos añadidos</StateTitle>
                            {services.products.map((service: IServices) => {
                                return (
                                    <StyledProductsDiv>
                                        <TitleServiceCard><ServiceName>{service.name}</ServiceName><ServicePrice>S/{(service.price)} {service.quantity !== 1 ? "x" + service.quantity : ""} </ServicePrice></TitleServiceCard>
                                        <AtempededServiceCard>Atendido por: {service.attended}</AtempededServiceCard>
                                    </StyledProductsDiv>
                                )
                            })}
                        </>
                        }
                        {(services.finished.length > 0) && <>
                            <StateTitle>Servicios terminados</StateTitle>
                            {services.finished.map((service: IServices) => {
                                return (
                                    <FinishedDiv>
                                        <TitleServiceCard><label>{service.name}</label><p>S/{(service.price * service.quantity)}</p></TitleServiceCard>
                                        <AtempededServiceCard>Atendido por: {service.attended}</AtempededServiceCard>
                                    </FinishedDiv>)
                            })}
                        </>}
                    </MainContainer>

                </Main>
            </BodyContainer>
            <FooterContainer>
                <StyledFooter>
                    <label>Total a pagar</label> <label>S/{totalPrice}</label>
                </StyledFooter>
            </FooterContainer>
        </>
    );
}

const Main = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    color: ${magenta_1};
`;

const MainContainer = styled(Main)`
    justify-content: center;
    width: 100%;
    min-height: fit-content;
    `;

const StyledEditIcon = styled(AiFillEdit)`
    font-size: 1.8rem;
`;

const StyledEditLabel = styled(StyledLabel)`
    font-size: 1rem;
    margin-bottom: 1rem;
    align-items: center;
    text-align: end;
    color: ${dark_purple};
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    text-decoration: underline;
    text-underline-position: under;
    font-weight: 500;
    cursor: pointer;
    width: auto;
`;

const StyledClientTitle = styled(StyledLabel)`
    color: ${purple_1};
    font-size: 1rem;
    font-weight: 700;
`;

const StyledClientDataMain = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    color: ${violet_1};
    flex-wrap: wrap;
`;

const ClientDataTitle = styled.label`
    color: ${purple_1};
    font-size: .8rem;
    font-weight: 600;
    margin: 5px 0;
    width: 50%;
    `;

const ClientDataContent = styled.label`
    color: ${purple_1};
    font-size: .8rem;
    font-weight: 400;
    margin: 5px 0;
    width: 50%;
    word-break: break-all;
    hyphens: auto;
`;

const StateTitle = styled.label`
    color: ${purple_1};
    font-size: .8rem;
    font-weight: 500;
    margin: 5px 0;
    `;

const InCourseDiv = styled(BorderDiv)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${soft_purple};
    border: none;
    color: white;
    width: -webkit-fill-available;
    `;
const InComingDiv = styled(BorderDiv)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${purple_1};
    width: -webkit-fill-available;
    label {
        color: ${purple_1};
    }
    `;
const FinishedDiv = styled(BorderDiv)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${violet_1}99;
    border-color: ${violet_1}99;
    width: -webkit-fill-available;
    label {
        color: ${violet_1}99
    }
    `;

const TitleServiceCard = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    width: -webkit-fill-available;
    label{
        font-size: 1rem;
        font-weight: 700;
        width: auto;
        word-break: break-all;
    }
    p{
        font-size: 1.3rem;
        font-weight: 700;
        margin: 0;
        margin-bottom: auto;
    }
    `;

const AtempededServiceCard = styled.label.attrs((props: { color?: string }) => ({ color: props.color || "initial" }))`
    font-size: .7rem;
    font-weight: 500;
    margin: 5px 0;
    color: ${(props) => props.color};
`;

const StyledFooter = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    width: -webkit-fill-available;
    height: auto;
    min-height: 64px;
    background-color: ${purple_1};
    color: white;
    bottom: 0;
    left: 0;
    label{
        text-align: center;
        font-size: 1.3rem;
        font-weight: 700;
    }
`;

const FooterContainer = styled.div`
    position: relative;
    bottom: 0;
    min-height: 4rem;
    `;

const StyledProductsDiv = styled.div`
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;  
    
    align-items: center;
    justify-content: center;
    width: -webkit-fill-available;
    min-width: -webkit-fill-available;
    height: auto;
    background-color: ${soft_gray};
    padding: 1rem;
    border-radius: .5rem;
    margin: 1rem 0;
    `;

    const ServiceName = styled.label`
    color: ${black_500};
    font-size: 0.8rem;
    font-weight: 400;
    `;
    
    const ServicePrice = styled.p`
    color:  ${black_500};
    margin: auto 0;
    `;
