import { useContext, useState } from "react";
import styled, { keyframes } from "styled-components";
import { userContext } from "../../../../context/UserContext/userContext";
import { TProductCard } from "../../../../interfaces/productAndServices";
import { violet_1 } from "../../../../components/styled/constant";
import { DropDownContainer, DropDownHeader, StyledInput, StyledLabel } from "../../../../components/styled/styled";
import { GrMoney } from "react-icons/gr";
import { BsTrash } from "react-icons/bs";
import useMixpanel from "hooks/useMixpanel/useMixpanel";


type Props = {
    index: string,
    handlerClick: (index: string, product: TProductCard, type: "p" | "s") => void,
    handlerChangeProducts: (index: number, amount: number, employeerId: number, product?: TProductCard) => void,
    product: any,
    handlerDeleteProduct: (index: string) => void,
    bgColor?: string
}

export default function CardProducts(props: Props): JSX.Element {
    const { track } = useMixpanel();
    const [fadeOut, setFadeOut] = useState(false);
    const { index, handlerClick, handlerChangeProducts, product, handlerDeleteProduct, bgColor } = props;
    const [amount, setAmount] = useState(product?.quantity || 1);
    const { userState } = useContext(userContext)
    const { user } = userState;


    type ThisProduct = {
        service: TProductCard,
        quantity: number,
        employeeId: number,
        nombreEstado: string | undefined,
        type: "p" | "s"
    }
    const thisProduct: ThisProduct = product;

    const handlerChangeAmount = (amount: number, product?: TProductCard): void => {
        if (amount !== 0 && user)
            handlerChangeProducts(Number(index), amount, user?.empleadoId, product);
    }

    const handlerDelete = (index: string): void => {
        track("EliminarP");
        setFadeOut(true);
        setTimeout(() => {
           handlerDeleteProduct(index);
        }, 500);
    }

    const onClickCard = () => {
        if (thisProduct.employeeId === user?.empleadoId)
            handlerClick(index, thisProduct.service, "p");
    }


    return <StyledFormGroup fadeOut={fadeOut}>
        {(thisProduct?.employeeId === user?.empleadoId) ?
            <StyledCounter><div onClick={() => { setAmount((amount - 1) ? amount - 1 : 1); handlerChangeAmount((amount - 1) ? amount - 1 : 1) }} style={{ width: '1rem' }}>-</div>{amount} und <div onClick={() => { setAmount(amount + 1); handlerChangeAmount(amount + 1) }} style={{ width: '1rem' }}>+</div></StyledCounter>
            : null
        }
        <StyledContainer
            style={{ alignItems: "flex-start" }}
            bgColor={bgColor}
        >
            <StyledLabel onClick={onClickCard}>Producto</StyledLabel>
            <div style={{ width: '100%' }} onClick={onClickCard}>

                <DropDownContainer
                    style={{ width: "100%" }}
                >
                    <DropDownHeader
                        style={{
                            margin: "1rem 0",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                        }}
                    >
                        {thisProduct?.service?.descripcion || "Seleccione un producto"}
                    </DropDownHeader>
                </DropDownContainer>
                <StyledLabel>Estilista</StyledLabel>
                <StyledInput
                    disabled
                    style={{ width: "-webkit-fill-available" }}
                    value={thisProduct?.service?.empleadoAtencion || user?.nombre + " " + user?.apellido}
                ></StyledInput>
            </div>
            <PriceContainer>
                <GrMoney height={"2rem"} />
                {thisProduct?.service!==undefined ?
                  <label>S/ {parseFloat((thisProduct?.service?.precio || 0).toString()) + ' x ' + amount + ' = '} S/ {parseFloat(((thisProduct?.service?.precio || 0) * (amount ? amount : 0)).toString())}</label>
                  :<label>S/. 00.00</label>}
                {((thisProduct?.employeeId === user?.empleadoId) || !thisProduct) && <p style={{ display: 'inherit', textDecorationLine: 'underline', textUnderlineOffset: '2px', cursor: 'pointer', alignItems: 'center', fontWeight: 500 }} onClick={() => handlerDelete(index)}>Eliminar <BsTrash /></p>}
            </PriceContainer>
        </StyledContainer>
    </StyledFormGroup>

}

const fadeInLeft = keyframes`
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
`;

const fadeOutRight = keyframes`
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
`;
const StyledFormGroup = styled("div").attrs((props: { fadeOut: boolean }) => ({ fadeOut: props.fadeOut }))`
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: -webkit-fill-available;
    position: relative; 
    transform: translateX(-100vw);
    animation: ${(props: { fadeOut: boolean }) => {
        return props.fadeOut ? fadeOutRight : fadeInLeft
    }} 0.5s ease-in-out;
    animation-fill-mode: forwards;
    z-index: 250;
    `;

const StyledContainer = styled.div.attrs((props: { bgColor?: string }) => ({ bgColor: props.bgColor }))`
    display: flex;
    background-color: ${(props) => props.bgColor || "none"};
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 5%;
    border-radius: 0.5rem;
    border: 1px solid ${violet_1};
    width: -webkit-fill-available;
`;

const PriceContainer = styled.div`
    color: ${violet_1};
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 5%;
    border-radius: 0.5rem;
    width: -webkit-fill-available;
    font-size: 1rem;
    font-weight: bold;
    & label{
        color: ${violet_1};
        margin-left: 1rem;
    }
    & svg {
        font-size: 1rem;
    }
    & > svg{
        font-size: 2rem;
        & path{
            stroke: ${violet_1};
        }
    }

`;

const StyledCounter = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
     div{
        align-items: center;
        text-align: center;
        border-radius: 21%;
        color: ${violet_1};
        border: 2px solid ${violet_1};
        font-weight: 900;
        font-size: 1.3rem;
        padding: 0rem 0.3rem;
        margin: 0rem 0.3rem;
        cursor: pointer;
     }

`;    