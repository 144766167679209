import { IServiceQueue, IServiceQueueRequest } from "../interfaces/service-queue";
import fetchTimeout from "../utils/fetchTimeout";
import { decodeTokenLS } from "../utils/token";
import { setHeaders, URL_REQUEST } from "./constants";
import { IRequest } from "../interfaces/request";

export async function getServiceQueue(): Promise<IServiceQueue[]> {
    let response: IServiceQueueRequest;
    let employeeId;
    let localId;
    const decoded = decodeTokenLS();
    if (decoded) {
        employeeId = decoded.user.empleadoId;
        localId = decoded.user.localId;
        const raw = JSON.stringify({
            empleadoId: employeeId,
            localId: localId,
        });

        let res: any = await fetchTimeout(URL_REQUEST + "/api/v1/admin/comanda/mvp/listarColaServicios", {
            "headers": setHeaders(),
            "body": raw,
            "method": "post",
        });
        response = await res.json();
        if (response.status === "200") {
            return response.data;
        }
        throw new Error(response.message);
    }
    throw new Error("Token Invalido, por favor vuelva a iniciar sesion");
    
}

export async function updateServiceState(state: number, detailId: number): Promise<IRequest> {
    let response: IRequest;
    const decoded = decodeTokenLS();
    if (decoded) {
        const raw = JSON.stringify({
            estado: state,
            comandaDetalleId: detailId,
        });

        let res: any = await fetchTimeout(URL_REQUEST + "/api/v1/admin/comanda/mvp/modificarEstadoServicio", {
            "headers": setHeaders(),
            "body": raw,
            "method": "put",
        });
        response = await res.json();
        if (response.status === "200") {
            return response;
        }
        throw new Error(response.message);
    }
    throw new Error("Token Invalido, por favor vuelva a iniciar sesion");
}
