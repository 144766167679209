import { UserProvider } from "./UserContext/userProvider";
import { ComandaProvider } from "./ComandaContext/ComandaProvider";
import { SearchProvider } from "./SearchContext/SearchProvider";
import { ProductProvider } from "./products/ProductsProvider";
import { ServiceProvider } from "./services/serviceProvider";

export function ContextProvider({ children }: any): any {
  return (
    <ServiceProvider>
      <ProductProvider>
        <UserProvider>
          <SearchProvider>
            <ComandaProvider>{children}</ComandaProvider>
          </SearchProvider>
        </UserProvider>
      </ProductProvider>
    </ServiceProvider>
  );
}

