import { useReducer } from "react";
import { userContext } from "./userContext"
import { userReducer } from "./userReducer";
import { IUser, IUserState } from "../../interfaces/user";

interface props {
    children: JSX.Element | JSX.Element[]
}

const INITIAL_STATE: IUserState = {
    user: undefined,
    token: undefined,
}



export const UserProvider = ({children}:props): any => {
  const [userState, dispatch ] = useReducer(userReducer, INITIAL_STATE);

  const setUser = (user: IUser | undefined) => {
    dispatch({
      type: "LOGIN",
      payload: user
    });
  };

  const setToken = (token: string | undefined) => {
    dispatch({
      type: "SET_TOKEN",
      payload: token
    });
  };

  return (
      <userContext.Provider value={{
          userState,
          setUser,
          setToken
      }}>
        {children}
        </userContext.Provider>
  )
}
