/* eslint-disable array-callback-return */
import { useContext, useEffect, useState, useCallback, useRef } from "react";
import { BsScissors } from "react-icons/bs";
import { GiSpiralBottle } from "react-icons/gi";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";
import { productContext } from "../../../context/products/ProductsContext";
import { serviceContext } from "../../../context/services/serviceContext";
import { userContext } from "../../../context/UserContext/userContext";
import { ICart } from "../../../interfaces/cart";
import { IComandaDetailOrdered, IComandaDetailRequest } from "../../../interfaces/comanda/comanda";
import { IProduct, IService, TProductCard, TServiceCard } from "../../../interfaces/productAndServices";
import { editComanda, getComandaDetail } from "../../../request/comanda";
import { getProductsAvaiable, getServicesAvaiable } from "../../../request/productAndServices";
import { orderComandaDetail } from "../../../utils/orderComandas";
import { showPopup } from "../../../utils/showAlert";
import { dark_purple, gray_1, green_success, purple_1, white } from "../../../components/styled/constant";
import { BodyContainer, Main, StyledInput } from "../../../components/styled/styled";
import { OptionsStyle, PlusCircleAnimated, PlusCircleStyle, WhiteBackground } from "../../../components/styled/styledAddButton";
import CardProducts from "../add/cards/cardProducts";
import CardServices from "../add/cards/cardServices";
import AddProductModal from "../add/modal/addProductModal";
import AddServiceModal from "../add/modal/addServiceModal";
import { showLoader } from "../../../utils/loader";
import { decodeTokenLS } from "../../../utils/token";
import { socket } from '../../../utils/socket';
import useMixpanel from "hooks/useMixpanel/useMixpanel";
import { comandaContext } from "context/ComandaContext/ComandaContext";

export default function EditComanda() {
    const { track } = useMixpanel();
    const { id } = useParams();
    const navigate = useNavigate();
    const { productState, setProductsAvaiable } = useContext(productContext);
    const { productsAvaiable } = productState;
    const { serviceState, setServicesAvaiable } = useContext(serviceContext);
    const { servicesAvaiable } = serviceState;
    const { userState } = useContext(userContext);
    const comandaState = useContext(comandaContext);
    const [isLoading, setIsLoading] = useState(true);
    const [comandaDetail, setComandaDetail] = useState<IComandaDetailOrdered>({} as IComandaDetailOrdered);
    const [total, setTotal] = useState(0);
    const [totalInicial, setTotalInicial] = useState(0);
    const [deleteComanda, setDeleteComanda] = useState(false);
    const [addPoS, setAddPoS] = useState<boolean>(false);
    const [showServiceModal, setShowServiceModal] = useState(false);
    const [showProductModal, setShowProductModal] = useState(false);
    const [aux, setAux] = useState<TServiceCard | TProductCard | undefined>(undefined);
    const [index, setIndex] = useState<number | undefined>(undefined);
    const [cart, setCart] = useState<(ICart | undefined)[]>([]);
    const saveButtonRef = useRef<HTMLButtonElement>(null);

    let empleadosId: number[] = []

    const initialCharge = useCallback(async () => {
        try {
            if (id !== undefined) {
                const response: IComandaDetailRequest = await getComandaDetail(Number(id));
                const comandasOrd: IComandaDetailOrdered = orderComandaDetail(response);

                setComandaDetail(comandasOrd);
                const handlerCart: any[] = [];

                /* Empleados de involucrados en la comandas*/
                response.data.map(service => {
                    let handler = empleadosId.find(item => item === service.empleadoId)
                    if (handler === undefined) {
                        empleadosId.push(service.empleadoId)
                    }
                })

                comandasOrd.services.forEach((service) => {
                    handlerCart.push({
                        service: {
                            fechaCreacion: service.fechaCreacion,
                            observaciones: service.observaciones,
                            precio: service.precio,
                            productoId: service.productoId,
                            servicio: service.servicio,
                            servicioId: service.servicioId,
                            descripcion: service.servicio,
                            employeerId: service.empleadoId,
                            nombreEstado: service.nombreEstado,
                            detalleId: service.detalleId,
                            empleadoAtencion: service.empleadoAtencion,
                        },
                        quantity: service.cantidad,
                        employeeId: service.empleadoId,
                        nombreEstado: service.nombreEstado,
                        type: service.servicioId !== 0 ? "s" : "p"
                    });
                });
                setCart(handlerCart);
                setTotalInicial(handlerCart.reduce((acc, curr) => acc + (curr.service.precio * curr.quantity), 0));
            }

            if (servicesAvaiable.length > 0)
                if (servicesAvaiable[0].descripcion === "No listado") {
                    const services = await getServicesAvaiable();
                    setServicesAvaiable(services);
                }
            if (productsAvaiable.length > 0)
                if (productsAvaiable[0].descripcion === "No listado") {
                    const products = await getProductsAvaiable();
                    setProductsAvaiable(products);
                }
            setIsLoading(false);
        } catch (err: any) {
            setIsLoading(false);
            setTimeout(() => { // se envia con retraso porque sino se cierra el modal
                showPopup({
                    message: err.message,
                    confirmButtonText: "Recargar Página",
                }).then(() => {
                    window.location.reload();
                });
                console.log(err);
            }, 500);
        }
    }, [servicesAvaiable, setProductsAvaiable, setServicesAvaiable, id]);// eslint-disable-line



    useEffect(() => {
        setIsLoading(true);
        initialCharge();
    }, [initialCharge]);

    useEffect(() => {
        if (isLoading) {
            showLoader()
        }
        else {
            Swal.close();
        }
    }, [isLoading])

    useEffect(() => {
        let handlerTotal = 0;
        cart.forEach((item) => {
            if (item !== undefined) {
                const myItem = item as any;
                if (myItem.type === "p") {
                    handlerTotal += (myItem.service?.precio || 0) * (myItem.quantity || 0);
                    console.log(handlerTotal)
                } else {
                    handlerTotal += myItem.service?.precio || 0;
                }
                setTotal(handlerTotal);
            }
        });
    }, [cart])


    const handleDelete = (index: string) => {
        const newCart = [...cart];
        newCart.splice(Number(index), 1, undefined);
        setCart(newCart);
    }

    const handlerClick = (index: string, item: TServiceCard | TProductCard, type: "p" | "s") => {
        setAux(item);
        setIndex(Number(index));
        if (type === "s") {
            setShowServiceModal(true);
        }
        else {
            setShowProductModal(true);
        }
    }

    const handlerChangeServices = (index: number, amount: number, employeerId: number, e?: TServiceCard) => {
        const newCart = [...cart];
        const newItem = newCart[index];
        if (newItem !== undefined) {
            newItem.quantity = amount;
            newItem.service = e;
            newItem.employeeId = employeerId;
            setCart(newCart);
        }
    }

    const handlerChangeProducts = (index: number, amount: number, employeerId: number, e?: TProductCard) => {
        const newCart = [...cart];
        const newItem = newCart[index];
        if (newItem !== undefined) {
            newItem.quantity = amount;
            newItem.service = e || newItem.service;
            newItem.employeeId = employeerId;
            setCart(newCart);
        }
    }

    const validateSaveComanda = async () => {
        if (cart.every(item => item === undefined)) {
            const res = await showPopup({
                title: 'Esta comanda será eliminada',
                message: 'Usted ha eliminado todos los productos o servicios que contiene',
                confirmButtonText: "Salir y eliminar la comanda",
                cancelButtonText: 'Seguir editando'
            })

            if (res.isConfirmed) {
                track("SaliryEliminarC")
                setDeleteComanda(true);
                handlerSubmit();
            }
            track("SeguirEditando")
        } else {
            handlerSubmit();
        }
    }
    const handleCloseModal = () => {
        setShowServiceModal(false);
        setShowProductModal(false);
        setAux(undefined);
        setIndex(undefined);
    }
    const handlerAddSoPModal = (e: IService | IProduct, type: "s" | "p", quantity?: number) => {
        let newCart = [...cart];
        if (index !== undefined) {
            newCart.splice(index, 1, {
                service: e,
                quantity: quantity || 1,
                employeeId: userState.user?.empleadoId || 0,
                nombreEstado: "EN ESPERA",
                type
            });
        }
        else {
            newCart.push({
                service: e,
                quantity: 1,
                employeeId: userState.user?.empleadoId || 0,
                nombreEstado: "EN ESPERA",
                type
            });
            if (saveButtonRef) {
                setTimeout(() => {
                    saveButtonRef?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
                }, 370)
            }
        }
        setCart(newCart);
        setShowServiceModal(false);
        setShowProductModal(false);
        setIndex(undefined);
    }

    const handlerClickAdd = (type: "s" | "p") => {
        setAux(undefined);
        if (type === "s") {
            setShowServiceModal(true);
        }
        else {
            setShowProductModal(true);
        }
    }


    const backToDetails = async () => {
        if (total !== totalInicial) {
            const res = await showPopup({
                title: 'Si abandonas tus cambios no se guardaran',
                confirmButtonText: "Salir",
                cancelButtonText: 'Seguir editando'
            })

            if (res.isConfirmed) {
                navigate(`/comandas/detalle/${id}/1`)
            }
        } else {
            navigate(`/comandas/detalle/${id}/1`)
        }
    }

    const socketEdit = () => {
        const decoded = decodeTokenLS();
        if (decoded) {
            socket.emit("vtf_comanda_actualizacion", {
                empresaId: decoded.user.empresaId,
                empleadosId: empleadosId,
                comandaId: id,
                localId: decoded.user.localId
            });
        }
    }

    const handlerSubmit = async () => {
        setIsLoading(true);
        type TDetalle = { servicioId: number | null, productoId: number | null, cantidad: number, detalleId: number | null };
        let detalle: TDetalle[]
        try {

            detalle = cart.filter(item => item !== undefined).map((item: any) => {
                if (item?.type === "s") {
                    return {
                        servicioId: item.service?.servicioId || null,
                        productoId: null,
                        cantidad: item.quantity,
                        detalleId: item.service?.detalleId || null
                    }
                }
                else {
                    return {
                        servicioId: null,
                        productoId: item.service?.productoId || null,
                        cantidad: item.quantity,
                        detalleId: item.service?.detalleId || null
                    }
                }
            })
            //Para crear
            if (!cart.every(item => (item?.service !== undefined || item === undefined))) {
                throw new Error("No dejes ningun servicio o producto sin seleccionar")
            }


            const response = await editComanda(Number(id), "", detalle);
            if (response.status === "200") {
                if (!cart.some(item => item?.employeeId === userState.user?.empleadoId)) {
                    if (id)
                        comandaState.deleteComanda(id);
                }
                track('GuardarCambiosC', {
                    data: deleteComanda ? "Se ha eliminado la comanda" : "Se ha editado la comanda",
                })
                socketEdit();
                setTimeout(() => { // se envia con retraso porque sino se cierra el modal
                    showPopup({
                        message: deleteComanda ? "Se ha eliminado la comanda" : "Se ha editado la comanda",
                        confirmButtonText: "Aceptar",
                        icon: "success"
                    }).then(() => {
                        setIsLoading(false);
                        setTimeout(() => { // se envia con retraso porque sino se cierra el modal
                            navigate(`/comandas/detalle/${id}/1`)
                        }, 500);
                    });
                }, 500);
            }
            else {
                showPopup({
                    message: response.message,
                    confirmButtonText: "Aceptar",
                    icon: "error"
                }).then(() => {
                    setIsLoading(false);
                });
            }
            setDeleteComanda(false);

        } catch (err: any) {
            console.log(err);
            setTimeout(() => { // se envia con retraso porque sino se cierra el modal
                showPopup({
                    message: err || 'No se pudo crear la comanda',
                    icon: "error",
                }).then(() => {
                    setIsLoading(false);
                });
            }, 500);
        }
    }
    useEffect(() => {
        return () => {
            Swal.close();
        }
    }, [])


    return (
        <>
            <AddServiceModal
                show={showServiceModal}
                handleCloseModal={handleCloseModal}
                handlerAddSoPModal={handlerAddSoPModal}
                key={"ModalAddService"}
                service={aux}
                servicesAvaiable={servicesAvaiable}
            />
            <AddProductModal
                show={showProductModal}
                handleCloseModal={handleCloseModal}
                handlerAddSoPModal={handlerAddSoPModal}
                key={"ModalAddproduct"}
                product={aux}
                productsAvailable={productsAvaiable}
            />
            <BodyContainer>

                {isLoading &&
                    <WhiteBackground style={{ zIndex: 1000 }} />}
                <MainBackground />
                {
                    addPoS &&
                    <WhiteBackground onClick={() => setAddPoS(false)} />
                }

                <MainContainer>
                    <StyledLabelTitle onClick={() => backToDetails()}>&#60; Regresar</StyledLabelTitle>
                    {
                        userState.user?.tipoPrivacidadDatosCliente !== 3 &&
                        <>
                            <StyledLabel>Documento de identidad</StyledLabel>
                            <StyledGroup>
                                <StyledInputInverse value={comandaDetail?.clientData?.docType || ""} disabled style={{ width: '15%' }} />
                                <StyledInputInverse value={comandaDetail?.clientData?.docNumber || ""} disabled style={{ width: '70%' }} />
                            </StyledGroup>
                        </>
                    }
                    <StyledLabel>Nombre del cliente</StyledLabel>
                    <StyledGroup>
                        <StyledInputInverse value={(comandaDetail?.clientData?.name + ' ' + ((userState.user?.tipoPrivacidadDatosCliente !== 3 || userState.user?.empleadoTipoId !== 2) ? comandaDetail?.clientData?.lastName : comandaDetail?.clientData?.lastName[0] + '.')) || ""} disabled />
                    </StyledGroup>
                    {
                        cart.length > 0 &&
                        cart.map((item, index) => {
                            if (item !== undefined) {
                                if (item.type === "p") {
                                    return <CardProducts
                                        handlerClick={handlerClick}
                                        handlerChangeProducts={handlerChangeProducts}
                                        key={index}
                                        handlerDeleteProduct={handleDelete}
                                        index={index.toString()}
                                        product={item}
                                        bgColor={gray_1}
                                    ></CardProducts>
                                }
                                return <CardServices
                                    handlerClick={handlerClick}
                                    handlerChangeServices={handlerChangeServices}
                                    key={index}
                                    handlerDeleteService={handleDelete}
                                    index={index.toString()}
                                    service={item}
                                    bgColor={gray_1}
                                ></CardServices>
                            }
                            return null;
                        })
                    }
                    <div style={{ position: 'absolute', bottom: '25%', right: '10%', zIndex: 300 }}>
                        <PlusCircleAnimated
                            turn={addPoS}
                        >
                            <PlusCircleStyle shadow={true} color="secundario" onClick={() => setAddPoS(!addPoS)} />
                        </PlusCircleAnimated>
                        {addPoS &&
                            <OptionsStyle onClick={() => { track("Añadir SoP (en edicion)"); setAddPoS(!addPoS) }}>
                                <p onClick={() => { track("Añadir Servicio (en edicion)"); handlerClickAdd("s") }}>Servicio&nbsp;&nbsp;&nbsp;<BsScissors></BsScissors></p>
                                <p onClick={() => { track("Añadir producto (en edicion)"); handlerClickAdd("p") }}>Producto             <GiSpiralBottle></GiSpiralBottle></p>
                            </OptionsStyle>
                        }
                    </div>
                    <StyledButton ref={saveButtonRef} onClick={validateSaveComanda}>Guardar cambios</StyledButton>

                </MainContainer>
            </BodyContainer>
            <StyledTotalContainer>
                <StyledTotal>Total a pagar</StyledTotal>
                <StyledTotalChildContainer>
                    <StyledTotal>S/ {total}</StyledTotal>
                </StyledTotalChildContainer>

            </StyledTotalContainer>
        </>
    )
}

const MainContainer = styled(Main)`
    background-color: ${purple_1};
    height: 100%;
`;

const MainBackground = styled.div`
    background-color: ${purple_1};
    width: -webkit-fill-available;
    height: 100%;
    z-index: -1;
    position: fixed;

`;

const StyledLabelTitle = styled.label`
    color: white;
    font-size: 0.8rem;
    line-height: 0.9rem;
    font-weight: 600;
    width: auto;
    cursor: pointer;
    margin-bottom: 2rem;
    margin-top: 1rem;
`;

const StyledLabel = styled.label`
    color: white;
    font-size: 0.7rem;
    font-weight: 500;
`;

const StyledInputInverse = styled(StyledInput)`
    background-color: #ffffff00;
    border: 1px solid #ffffff;
    outline: none;
    color: #ffffff;
`;

const StyledGroup = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
`;

const StyledButton = styled.button`
    background-color: ${white};
    border: none;
    border-radius: 0.5rem;
    width: 100%;
    font-size: 1.3rem;
    font-weight: 500;
    padding: .4rem;
    color: ${dark_purple};
    margin-top: 3rem;
    margin-bottom: 1rem;
`;

const StyledTotalContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: ${green_success};
    width: 100%;
    bottom: 0;
    padding: 2rem 0;
    z-index: 275;
`;

const StyledTotal = styled.label`
    color: white;
    font-size: 1.3rem;
    font-weight: 700;
    text-align: center;
`;

const StyledTotalChildContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;