import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { IRequest } from "../interfaces/request";
import { IUser, IUserRequest } from "../interfaces/user";
import { loginReq, recoverDataUser, recoverPassReq } from "../request/login";
import { isExpired, decodeToken } from "react-jwt";
import { userContext } from "../context/UserContext/userContext";
import { showPopup } from "../utils/showAlert";
import { showLoader } from "../utils/loader";

import useMixpanel from 'hooks/useMixpanel/useMixpanel';



export const useLogin = () => {
    const navigate = useNavigate();
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const context = useContext(userContext);
    const { userState, setUser, setToken } = context;
    const { user } = userState;
    const location = useLocation();
    const { track } = useMixpanel();

    useEffect(() => {
        if (loading) {
            showLoader();
        }
        else {
            Swal.close();
        }
    }, [loading])

    const login = useCallback(async (email?: string, clave?: string) => {
        setLoading(true);
        try {
            if (!email || !clave) {
                const token = localStorage.getItem("token");
                if (token) {
                    const decoded: any = decodeToken(token);
                    if (!isExpired(token) && decoded?.data) {
                        if (!userState?.user) {
                            const user = await recoverDataUser();
                            setUser(user);
                            setToken(token);
                        }
                        if (location.pathname === "/inicio") {
                            return navigate("/");
                        }
                        return;
                    }
                    track("Token expirado");
                    showPopup({
                        message: "Su sesión ha expirado, por favor inicie sesión nuevamente",
                        title: "Sesión expirada",
                        icon: "error"
                    });
                }
                return navigate("/inicio");
            }
            const res: IUserRequest = await loginReq(email, clave);
            if (res.status === "200") {
                setError(undefined);
                let usuario: IUser;
                res.data?.token && localStorage.setItem("token", res.data.token);
                if (res.data?.token) {
                    const decode: any = decodeToken(res.data.token);
                    let { data }: { data: IUser } = decode;
                    usuario = data;
                    setUser(usuario);
                    setToken(res.data.token);
                    track("Iniciar sesion exitoso");
                }
                navigate("/comandas");
            } else {
                setError(res.message);
                track("Iniciar sesion fallido", { data: res.message });
                await showPopup({
                    message: `${res.message}`,
                    icon: "error"
                });
            }
        } catch (error: any) {
            console.log(error);
            track("Iniciar sesion fallido", error.message);
            await showPopup({
                message: (error.message && error.message !== "Failed to fetch") || "Parece que no tienes conexión a internet, por favor revisa tu conexión y recarga la página",
                icon: "error",
                confirmButtonText: "Recargar"
            });
            window.location.reload();
        } finally {
            setLoading(false);
            Swal.close();
        }
    }, [navigate]);// eslint-disable-line

    const logout = () => {
        track("Cerrar sesion");
        localStorage.removeItem("token");
        window.location.reload();
    }



    const recoverPass = async (email: string) => {
        setLoading(true);
        try {
            const res: IRequest = await recoverPassReq(email);
            setLoading(false);
            if (res.status === "200") {
                track("Recuperación de contraseña exitosa");
                setTimeout(() => {
                    showPopup({
                        message: "Hemos enviado un correo para la recuperación de la cuenta",
                        icon: "success",
                        confirmButtonText: "Aceptar",
                    })
                }, 300);
            } else {
                track("Recuperación de contraseña fallida", { data: res.message });
                setTimeout(() => {
                    showPopup({
                        message: `${res.message}`,
                        icon: "error"
                    })
                }, 300);
            }
        } catch (error) {
            track("Recuperación de contraseña fallida", { data: error });
            setTimeout(() => {
                showPopup({
                    message: `${'Ocurrio un error'}`,
                    icon: "error"
                })
            }, 300);
            return
        } finally {
            setLoading(false);
        }
    }

    return {
        user,
        error,
        loading,
        recoverPass,
        login,
        logout
    }
}