import { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import { productContext } from '../../../../context/products/ProductsContext';
import { serviceContext } from '../../../../context/services/serviceContext';
import { IDocumentTypes } from '../../../../interfaces/cliente';
import { getClient, getDocumentList } from '../../../../request/client';
import { getProductsAvaiable, getServicesAvaiable } from '../../../../request/productAndServices';
import { showPopup } from '../../../../utils/showAlert';
import { DropDownContainer, DropDownHeader, DropDownList, DropDownListContainer, ListItem, StyledInput, StyledLabel } from '../../../../components/styled/styled';
import { TInputClient, TErrorsClient } from '../addComanda';
import { validate } from '../../../../utils/validate';
import { showLoader } from "../../../../utils/loader";
import { userContext } from '../../../../context/UserContext/userContext';


type TProps = {
    input: TInputClient;
    setInput: React.Dispatch<React.SetStateAction<TInputClient>>;
    documentType: string;
    setDocumentType: React.Dispatch<React.SetStateAction<string>>;
    documentList: IDocumentTypes[];
    setDocumentList: React.Dispatch<React.SetStateAction<IDocumentTypes[]>>;
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    formErrors: TErrorsClient;
    setFormErrors: React.Dispatch<React.SetStateAction<TErrorsClient>>;
};

const ClientData = ({
    input,
    setInput,
    documentType,
    setDocumentType,
    documentList,
    setDocumentList,
    isLoading,
    setIsLoading,
    formErrors,
    setFormErrors
}: TProps) => {
    const { productState, setProductsAvaiable } = useContext(productContext);
    const { productsAvaiable } = productState;
    const { serviceState, setServicesAvaiable } = useContext(serviceContext);
    const { servicesAvaiable } = serviceState;
    const { userState: { user } } = useContext(userContext);

    const [isOpenGender, setIsOpenGender] = useState(false)

    const [isOpen, setIsOpen] = useState(false);

    const [isSearch, setIsSearch] = useState(false);

    const [typeInput, setTypeInput] = useState('number');

    const genders = [{ name: "Masculino", id: 1 }, { name: "Femenino", id: 2 }];

    const toggling = () => setIsOpen(!isOpen);

    const onOptionClicked = (value: string) => {
        setDocumentType(value);
        cleanForm(true);
        setTypeInput(value === "DNI" || value === "RUC" ? 'number' : 'text');
        setIsOpen(false);
    };

    const cleanForm = (cleanAll:boolean) => {
        setInput({
            ...input,
            docNumber:cleanAll?'':input.docNumber,
            clientId: null,
            name: "",
            lastName: "",
            email: "",
            phone: "",
            gender: 0,
            birth: ""
        });

        setFormErrors({
            docNumber:cleanAll?'':formErrors.docNumber,
            email: '',
            phone: '',
            name: '',
            lastName: '',
            birth: '',
            gender: ''
        })
    }

    const handlerGetClient = async () => {
        setIsSearch(true);
        const docIdType = documentList.find(doc => doc.vtf_vch_nombre === documentType);
        if (!docIdType) {
            return showPopup({
                message: "Error al obtener el tipo de documento",
                icon: "error",
            });
        }
        try {
            if (input.docNumber.length > 7){
                const res = await getClient(input.docNumber, docIdType.vtf_int_idtipodocumento)
                setInput({
                    name: res.nombre.capitalizeFirstLetterAfter(" "),
                    email: res.correo,
                    phone: res.telefono,
                    docNumber: input.docNumber,
                    gender: res.sexo,
                    lastName: res.apellido?.capitalizeFirstLetterAfter(" "),
                    birth: res.fechaNacimiento,
                    clientId: res.clienteId

                })

                setFormErrors({
                    ...formErrors,
                    email: '',
                    phone: '',
                    name: '',
                    lastName: '',
                    birth: '',
                    gender: ''
                })
            }else{
                return false;
            }
               
        }
        catch (err: any) {
            cleanForm(false);
            return showPopup({
                message: "Error al obtener el cliente",
                icon: "error",
            });
        }
        finally {
            setIsSearch(false);
        }

    }
    const initialCharge = useCallback(async () => {
        try {
            const docs = await getDocumentList();
            if (servicesAvaiable.length > 0)
                if (servicesAvaiable[0].descripcion === "No listado") {
                    const services = await getServicesAvaiable();
                    setServicesAvaiable(services);
                }
            if (productsAvaiable.length > 0)
                if (productsAvaiable[0].descripcion === "No listado") {
                    const products = await getProductsAvaiable();
                    setProductsAvaiable(products);
                }
            setDocumentList(docs.data);
        } catch (err: any) {
            setTimeout(() => { // se envia con retraso porque sino se cierra el modal
                showPopup({
                    message: "Recargar Página",
                    icon: "error",
                }).then(() => {
                    window.location.reload();
                });
                console.log(err);
            }, 500);
        }
        finally {
            setIsLoading(false);
        }
    }
        , [setProductsAvaiable, setServicesAvaiable]); // eslint-disable-line

    useEffect(() => {
        setIsLoading(true);
        initialCharge();
    }, [initialCharge, setIsLoading]);

    useEffect(() => {
        if (isLoading) {
            showLoader()
        }
        else {
            Swal.close();
        }
    }, [isLoading])



    const handlerInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = e.target;
        if (name === "name") {
            value = value.capitalizeFirstLetterAfter(" ");
        }
        if (name === "lastName") {
            value = value.capitalizeFirstLetterAfter(" ");
        }

        if (name === "phone") {
            if (value.length > 9) return false;
        }

        if (name === "docNumber") {
            if (documentType === "RUC" && value.length > 11) return false;
            if (documentType === "DNI" && value.length > 8) return false;
            if ((documentType === "CARNÉ DE EXTRANJERIA" || documentType === "PASAPORTE") && value.length > 12) return false;
        }

        setFormErrors({
            ...formErrors,
            [e.target.name]: validate(e.target.value, e.target.name, documentType),
        });

        setInput({
            ...input,
            [name]: value
        });
    }

    return (
        <>
            <StyledLabel>Documento de identidad</StyledLabel>
            <StyledFormGroup>

                <DropDownContainer>
                    <DropDownHeader
                        style={{ marginRight: '3px', maxWidth: '3rem' }}
                        onClick={toggling}
                        dropDown={true}
                    >
                        {documentType}
                    </DropDownHeader>
                    {isOpen && (
                        <DropDownListContainer>
                            <DropDownList>
                                {documentList.map((item) => (
                                    <ListItem onClick={() => {onOptionClicked(item.vtf_vch_nombre)}} key={Math.random()}>
                                        {item.vtf_vch_nombre}
                                    </ListItem>
                                ))}
                            </DropDownList>
                        </DropDownListContainer>
                    )}
                </DropDownContainer>
                <StyledInput className={formErrors.docNumber ? "error" : ""} disabled={isSearch} name="docNumber" onBlur={handlerGetClient} value={input.docNumber} onChange={handlerInput} type={typeInput} />
            </StyledFormGroup>
            <p className="textError">{formErrors.docNumber}</p>

            <StyledLabel>Nombre</StyledLabel>
            <StyledFormGroup>
                <StyledInput className={formErrors.name ? "error" : ""} disabled={isSearch} name="name" value={input.name || ""} onChange={handlerInput} />
            </StyledFormGroup>
            <p className="textError">{formErrors.name}</p>

            <StyledLabel>Apellido</StyledLabel>
            <StyledFormGroup>
                {   //user?.tipoPrivacidadDatosCliente !== 3 ?
                    <StyledInput className={formErrors.lastName ? "error" : ""} disabled={isSearch} name="lastName" value={input.lastName || ""} onChange={handlerInput} />
                    //<StyledInput className={formErrors.lastName ? "error" : ""} disabled={true} name="lastName" value={input.lastName.slice(0,1) + "." || ""} />// apellido abreviado
                }
            </StyledFormGroup>
            <p className="textError">{formErrors.lastName}</p>
            {(user?.tipoPrivacidadDatosCliente !== 3 || user?.tipoUsuario !== 2) ?
                <>
                    <StyledLabel>Correo</StyledLabel>
                    <StyledFormGroup>
                        <StyledInput className={formErrors.email ? "error" : ""} disabled={isSearch} name="email" value={input.email || ""} onChange={handlerInput} />
                    </StyledFormGroup>
                    <p className="textError">{formErrors.email}</p>

                    <StyledLabel>Celular</StyledLabel>
                    <StyledFormGroup>
                        <StyledInput className={formErrors.phone ? "error" : ""} disabled={isSearch} name="phone" value={input.phone || ""} onChange={handlerInput} type="number" />
                    </StyledFormGroup>
                    <p className="textError">{formErrors.phone}</p>

                    <StyledLabel>Fecha de nacimiento</StyledLabel>
                    <StyledFormGroup>
                        <StyledInput disabled={isSearch} type='date' name="birth" value={input.birth || ""} onChange={handlerInput} />
                    </StyledFormGroup>
                    <p className="textError">{formErrors.birth}</p>

                    <StyledLabel>Sexo</StyledLabel>
                    <StyledFormGroup>

                        <DropDownContainer style={{ zIndex: 275 }}>
                            <DropDownHeader
                                style={{ marginRight: '3px' }}
                                onClick={() => { setIsOpenGender(!isOpenGender) }}
                                dropDown={true}
                            >
                                {genders.find(item => item.id === input.gender)?.name}
                            </DropDownHeader>

                            {isOpenGender && (
                                <DropDownListContainer>
                                    <DropDownList
                                        style={{ height: 'auto' }}
                                    >
                                        {genders.map((item) => (
                                            <ListItem onClick={() => { setIsOpenGender(false); setInput({ ...input, gender: item.id }) }} key={Math.random()}>
                                                {item.name}
                                            </ListItem>
                                        ))}
                                    </DropDownList>
                                </DropDownListContainer>
                            )}
                        </DropDownContainer>
                    </StyledFormGroup>
                </>
                :
                null
            }
        </>
    )
}

export default ClientData

const StyledFormGroup = styled.div`
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    align-items: center;
    position: relative;
`;