import { BsFillPlusCircleFill } from "react-icons/bs";
import styled, { keyframes } from "styled-components";
import { soft_purple, violet_1 } from "./constant";

const animation = keyframes`

    0% {
        height: 0;
    }
    99% {
        height: 277%;
    }
    100% {
        height: auto;
    }
`;

export const OptionsStyle = styled.div`{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    position: absolute;
    overflow: hidden;
    z-index: 350;
    animation: ${animation} .5s ease;
    animation-fill-mode: forwards;
    bottom: 100%;
    right: 0;
    p {
        white-space: nowrap;
        color:white;
        display: flex;
        align-items: center;
        cursor: pointer;
        & > svg {
            margin-left: 1rem;
            padding: 0.7rem;
            border-radius: 50%;
            background: ${soft_purple};
            overflow: visible;
        }
    }
}`

export const animationWhiteBackground = keyframes`

    0% {
        background-color: #00000000;
    }
    100% {
        #00000045;
    }`

export const WhiteBackground = styled.div`{
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-color: #00000045;
    top: 0px;
    z-index: 300;
    animation: ${animationWhiteBackground} .3s ease;
    animation-fill-mode: forwards;
    }`



export const PlusCircleStyle = styled(BsFillPlusCircleFill).attrs(({ color, shadow }: { color: "principal" | "secundario", shadow: boolean }) => ({ color, shadow }))`{
margin: 0 auto;
height: auto;
width: 3rem;
color: ${props => props.color === "secundario" ? "white" : violet_1};
cursor: pointer;
filter: ${props => props.shadow ? "drop-shadow(-2px -2px 4px rgb(0 0 0 / .9))" : "none"};
`

const rotateAnimation = keyframes`
    from{
        transform: rotate(0deg);

    }
    to{
        transform: rotate(45deg);
    }
`
const rotateAnimation2 = keyframes`
    from{
        transform: rotate(45deg);
    }
    to{
        transform: rotate(0deg);
    }
`

export const PlusCircleAnimated = styled("div").attrs((props: { turn: boolean }) => ({ turn: props.turn }))`
    z-index: 350;
    animation: ${(props) => (props.turn ? rotateAnimation : rotateAnimation2)} .3s ease-in-out;
    animation-fill-mode: forwards;
`