import { IProduct, IProductState } from "../../interfaces/productAndServices";

type TProducAction =
    | { type: "SET_PRODUCTS_AVAIABLE"; payload: IProduct[] }

export const productReducer = (state: IProductState, action: TProducAction): IProductState => {
    switch (action.type) {
        case "SET_PRODUCTS_AVAIABLE":
            return { ...state, productsAvaiable: action.payload };
        default:
            return state;
    }
}