/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useState } from 'react';
import CardComanda from "./cards/card-comanda";
import { BsFillPlusCircleFill } from 'react-icons/bs';
import { comandaContext } from '../../context/ComandaContext/ComandaContext';
import { useEffect } from 'react';
import { listarComandas } from '../../request/comanda';
import orderComandas from '../../utils/orderComandas';
import { searchContext } from '../../context/SearchContext/SearchContext';
import { IComandaList } from '../../interfaces/comanda/comanda';
import { NavLink } from 'react-router-dom';
import { BodyContainer, Main, StyledLabel } from '../../components/styled/styled';
import { purple_1 } from '../../components/styled/constant';
import styled from "styled-components";
import { white } from '../../components/styled/constant';
import Peace from './groups/peace';
import Loader from '../../components/elements/loader/loader';
import { showPopup } from '../../utils/showAlert';
import { getDay } from '../../utils/getDay';
import { userContext } from '../../context/UserContext/userContext';
import { socketInit, socket, refreshComandas, deleteRefreshComandas } from '../../utils/socket';
import useMixpanel from 'hooks/useMixpanel/useMixpanel';


export default function Comandas() {
  const { track } = useMixpanel();
  const [filteredComandas, setFilteredComandas] = useState<IComandaList[]>([]);
  const [showSearched, setShowSearched] = useState(false);
  const { userState: { user } } = useContext(userContext);
  const { comandaState, setComandas } = useContext(comandaContext);
  const { comandas } = comandaState;
  const { searchState } = useContext(searchContext);
  const { search } = searchState;
  const [isLoading, setIsLoading] = useState(false);

  const listarComandasFN = useCallback(async (refresh: boolean) => {
    if (refresh === false) { setIsLoading(true) }
    setShowSearched(false);
    try {
      let comandasReq = await listarComandas(search);
      let handler = orderComandas(comandasReq);
      setComandas(handler);
      setFilteredComandas(handler);
    }
    catch (err: any) {
      console.error(err);
      if (refresh === false) {
        showPopup({
          message: err.message,
          icon: 'error'
        });
      }
    }
    finally {
      setIsLoading(false);
    }
  }
    , [setComandas, search]);

  const getComandasFiltered = useCallback(async () => {
    setIsLoading(true);
    try {
      let comandasReq = await listarComandas(search);
      let handler = orderComandas(comandasReq);
      setShowSearched(true);
      setFilteredComandas(handler);
    }
    catch (err: any) {
      console.log("err", err);
      showPopup({
        message: err.message,
        icon: 'error'
      });
    }
    finally {
      setIsLoading(false);
    }
  }
    , [search]);


  useEffect(() => {
    if (!comandaState.comandas.length) {
      socketInit();
      listarComandasFN(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {

    socket.on("vtf_comanda_creacion", (mensaje) => {
      console.log('Creacion', mensaje)
      const handler = refreshComandas(mensaje.comanda)
      if (handler) { listarComandasFN(true); }
    });

    socket.on("vtf_comanda_actualizacion", (mensaje) => {
      console.log('Edicion de Comanda', mensaje)
      const handler = refreshComandas(mensaje.comanda)
      if (handler) { listarComandasFN(true); }
    });

    socket.on("vtf_comanda_eliminacion", ({
      comandaId,
      empleadosId
    }) => {
      console.log('Eliminacion', comandaId)
      const handler = deleteRefreshComandas(empleadosId)
      if (handler !== undefined) {
        listarComandasFN(true);
      }
    });
  }, [])

  //empresaId
  const handleSearch = useCallback((search: string) => {
    if (search) {
      const searchSplidet = search.split(' ');

      const searchFiltered = comandas.filter((comanda) => {
        let flag = false;
        searchSplidet.forEach((word) => {
          if (!word) { return flag; }
          if (comanda.clienteNombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(word.toLowerCase())) {
            flag = true;
          }
          if (comanda.clienteApellido.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(word.toLowerCase())) {
            flag = true;
          }
          if (comanda.nroDocumento?.toLowerCase().includes(word.toLowerCase())) {
            flag = true;
          }
        });
        return flag;
      });
      setFilteredComandas(searchFiltered);
    }
    else {
      setFilteredComandas(comandas);
    }
  }, [comandas]);

  const listarComandasGeneral = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    getComandasFiltered();
    track("BCactivas", { data: search });
  }


  useEffect(() => {
    handleSearch(search);
  }, [search, handleSearch]);
  useEffect(() => {
  }, [comandas, showSearched]);


  return (
    <BodyContainer>
      <Main>
        {isLoading ? <Loader /> :
          <>
            <StyledLabel
              color='purple_1'
            >{getDay()}</StyledLabel>


            {(comandas.length || showSearched) ?

              (filteredComandas.length ? filteredComandas.map((item) => {
                return <CardComanda key={"comanda nro: "  + item.id} data={item} />
              }) :
                <div>
                  <label style={{ color: 'black', textAlign: 'center', marginTop: '4rem' }}>No se han encontrado resultados</label>
                </div>) :
              <>
                {(!!search.length || !showSearched) &&
                  <Peace
                    icon='peace1'
                    text='Toma un respiro, no tienes comandas asignadas aún'
                  />
                }
              </>}
            {
              (search.length > 2) && <>
                <br></br>
                <StyledButton onClick={listarComandasGeneral} type="button">Buscar en todas las comandas</StyledButton>
              </>
            }
          </>
        }
        <NavLink onClick={() => track("CrearComanda2")} to={'agregarcomanda'}>
          <BsFillPlusCircleFill style={{ position: 'absolute', bottom: '10%', right: '4%', height: 'auto', width: '3rem', color: purple_1, background: 'radial-gradient(circle, #ffffff 50%, #ffffff00 50%', borderRadius: '99%', filter: 'drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))', display: (user?.crearComanda) ? "block" : "none" }} />
        </NavLink>
      </Main>
    </BodyContainer>

  );
}


const StyledButton = styled.button`
    background-color: rgba(68, 48, 140, 1);
    border: none;
    border-radius: 0.5rem;
    width: 100%;
    font-size: 1.0rem;
    font-weight: 500;
    padding: 10px 0px 10px 0px;
    color: ${white};
    margin-bottom: 1rem;
    cursor : pointer;
`;