import { useContext, useEffect, useRef, useState } from "react";
import { IDocumentTypes } from "../../../interfaces/cliente";
import { BodyContainer, Main, StyledButton, StyledLabel } from "../../../components/styled/styled";
import { violet_1 } from "../../../components/styled/constant";
import { BsScissors } from "react-icons/bs";
import { GiSpiralBottle } from "react-icons/gi";
import { socket } from '../../../utils/socket';
import { decodeTokenLS } from "../../../utils/token";
import useNearScreen from "../../../hooks/useNearScreen/useNearScreen";

import { IProduct, IService, TProductCard, TServiceCard } from "../../../interfaces/productAndServices";
import CardServices from "./cards/cardServices";
import CardProducts from "./cards/cardProducts";
import { createComanda } from "../../../request/comanda";
import { productContext } from "../../../context/products/ProductsContext";
import { serviceContext } from "../../../context/services/serviceContext";
import { OptionsStyle, PlusCircleAnimated, PlusCircleStyle, WhiteBackground } from "../../../components/styled/styledAddButton";
import { useNavigate } from "react-router-dom";
import { userContext } from "../../../context/UserContext/userContext";
import { showPopup } from "../../../utils/showAlert";
import ClientData from "./clientData/clientData";
import { CollapseDataClient, CollapseDataClientHeader, PopOverContent } from "./styles";
import AddServiceModal from "./modal/addServiceModal";
import { ICart } from "../../../interfaces/cart";
import AddProductModal from "./modal/addProductModal";
import Swal from "sweetalert2";
import useMixpanel from "hooks/useMixpanel/useMixpanel";


export type TInputClient =
    {
        docNumber: string,
        name: string,
        lastName: string,
        email: string,
        phone: string,
        gender: number,
        birth: string,
        clientId: null | number,
    }

export type TErrorsClient =
    {
        email: string,
        phone: string,
        name: string,
        lastName: string,
        docNumber: string,
        birth: string,
        gender: string
    }

export default function AddComanda() {
    const { track } = useMixpanel();
    const { productState } = useContext(productContext);
    const { productsAvaiable } = productState;
    const { serviceState } = useContext(serviceContext);
    const { servicesAvaiable } = serviceState;
    const { userState } = useContext(userContext);
    const { user } = userState;
    const [showPopOver, setShowPopOver] = useState(true);
    const [displayPopover, setDisplayPopover] = useState(false);
    const [documentList, setDocumentList] = useState<IDocumentTypes[]>([]);
    const [documentType, setDocumentType] = useState<string>("DNI");
    const [isLoading, setIsLoading] = useState(true);
    const [addPoS, setAddPoS] = useState<boolean>(false);
    const [showServiceModal, setShowServiceModal] = useState(false);
    const [showProductModal, setShowProductModal] = useState(false);
    const [aux, setAux] = useState<TServiceCard | TProductCard | undefined>(undefined);
    const [index, setIndex] = useState<number | undefined>(undefined);
    const [collapseDataClient, setCollapseDataClient] = useState<boolean>(false);
    const [displayDataClient, setDisplayDataClient] = useState<string>("block");
    const [input, setInput] = useState<TInputClient>({
        docNumber: "",
        name: "",
        lastName: "",
        email: "",
        phone: "",
        gender: 0,
        birth: "",
        clientId: null,
    });

    const [formErrors, setFormErrors] = useState<TErrorsClient>({
        email: '',
        phone: '',
        name: '',
        lastName: '',
        docNumber: '',
        birth: '',
        gender: ''
    });

    const popover = useRef(null)
    const isPopOverNear = useNearScreen(popover, -50)
    const saveButtonRef = useRef<HTMLButtonElement>(null);

    const [cart, setCart] = useState<(ICart | undefined)[]>([]);

    const navigate = useNavigate();



    useEffect(() => {
        if (isPopOverNear) {
            setTimeout(() => {
                setShowPopOver(false)
            }, 3000)
        }
        return () => {
            Swal.close();
        }
    }, [isPopOverNear])

    useEffect(() => {
        setTimeout(() => {
            setDisplayPopover(showPopOver)
        }, 300)
    }, [showPopOver])

    useEffect(() => {
        collapseDataClient ? setTimeout(() => setDisplayDataClient("none"), 500) : setDisplayDataClient("block")
    }, [collapseDataClient])

    useEffect(() => {
        if (!user?.crearComanda) {
            navigate("/comandas")
        }
    }, [user])// eslint-disable-line

    const handleDelete = (index: string) => {
        const newCart = [...cart];
        newCart.splice(Number(index), 1, undefined);
        setCart(newCart);
    }

    const handlerChangeServices = (index: number, amount: number, employeerId: number, e?: IService) => {
        const newCart = [...cart];
        const newItem = newCart[index];
        if (newItem !== undefined) {
            newItem.quantity = amount;
            newItem.service = e;
            newItem.employeeId = employeerId;
            setCart(newCart);
        }
    }

    const handlerChangeProducts = (index: number, amount: number, employeerId: number, e?: IProduct) => {
        const newCart = [...cart];
        const newItem = newCart[index];
        if (newItem !== undefined) {
            newItem.quantity = amount;
            newItem.service = e || newItem.service;
            newItem.employeeId = employeerId;
            setCart(newCart);
        }
    }

    const handlerAddSoPModal = (e: IService | IProduct, type: "s" | "p", quantity?: number) => {
        let newCart = [...cart];
        if (index !== undefined) {
            newCart.splice(index, 1, {
                service: e,
                quantity: quantity || 1,
                employeeId: userState.user?.empleadoId || 0,
                nombreEstado: "EN ESPERA",
                type
            });
        }
        else {
            newCart.push({
                service: e,
                quantity: 1,
                employeeId: userState.user?.empleadoId || 0,
                nombreEstado: "EN ESPERA",
                type
            });
            if (saveButtonRef) {
                setTimeout(() => {
                    saveButtonRef?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
                }, 370)
            }

        }
        setCart(newCart);
        setShowServiceModal(false);
        setShowProductModal(false);
        setIndex(undefined);
    }

    const handleCloseModal = () => {
        setShowServiceModal(false);
        setShowProductModal(false);
        setAux(undefined);
        setIndex(undefined);
    }

    const handlerClick = (index: string, item: TServiceCard | TProductCard, type: "p" | "s") => {
        setAux(item);
        setIndex(Number(index));
        if (type === "s") {
            setShowServiceModal(true);
        }
        else {
            setShowProductModal(true);
        }
    }

    const validateForm = () => {
        let isvalidate = true

        setFormErrors({
            ...formErrors,
            name: input.name === '' ? 'Nombre es un campo requerido' : formErrors.name,
            birth: (input.birth === '' && user?.tipoPrivacidadDatosCliente === 1)? 'Fecha de Nacimiento es un campo requerido' : '',
            docNumber: input.docNumber === '' ? 'Num. documento es un campo requerido' : formErrors.docNumber,
        })

     
        if (Object.values(formErrors).some(item => item !== '') === true) {
            isvalidate = false
        }
        setCollapseDataClient(isvalidate ? true : false);
        return isvalidate;
    }

    const socketEdit = (idComanda: number) => {
        const decoded = decodeTokenLS();
        if(decoded) {
            socket.emit("vtf_comanda_actualizacion", {
                empresaId: decoded.user.empresaId,
                empleadosId: [],
                comandaId: idComanda,
                localId: decoded.user.localId
            });
        }
    }

    const handlerSubmit = async () => {
        const clienteId = input.clientId;
        const observaciones = "";
        type TDetalle = { servicioId: number | null, productoId: number | null, cantidad: number }
        let detalle: TDetalle[]
        try {

            detalle = cart.filter(item => item !== undefined).map((item: any) => {
                if (item?.type === "s") {
                    return {
                        servicioId: item.service?.servicioId || null,
                        productoId: null,
                        cantidad: item.quantity
                    }
                }
                else {
                    return {
                        servicioId: null,
                        productoId: item.service?.productoId || null,
                        cantidad: item.quantity
                    }
                }
            })

            const cliente = {
                tipoDocumentoId: documentList.find(doc => doc.vtf_vch_nombre === documentType)?.vtf_int_idtipodocumento || 2,
                nroDocumento: input.docNumber,
                nombre: input.name,
                apellido: input.lastName,
                correo: input.email,
                telefono: input.phone,
                sexo: input.gender,
                fechaNacimiento: input.birth
            }
          
            if (validateForm() === false) {
                throw new Error("Algunos campos del Cliente no son válidos");
            }

            if (detalle.length === 0)
                throw new Error("Debes seleccionar por lo menos un producto o servicio");

            if (!cart.every(item => (item?.service !== undefined || item === undefined)) || !cart.some(item => item !== undefined))
                throw new Error("No dejes ningun servicio o producto sin seleccionar")


            const res = await createComanda({ cliente, clienteId, observaciones, detalle });
            if (res.status === "200") {
                socketEdit(res.data.comandaId);
                console.log(res, 'create comanda desde estilista')
                await showPopup({
                    message: "Comanda creada con exito",
                    icon: "success",
                });
              window.location.href = '/comandas';
            }
            else {
                throw new Error(res.message);
            }
        }
        catch (err: any) {
            return showPopup({
                message: err.message || "Ocurrio un error, intentelo nuevamente mas tarde",
                icon: "error",
            });
        }
    }


    return (
        <>
            <AddServiceModal
                show={showServiceModal}
                handleCloseModal={handleCloseModal}
                handlerAddSoPModal={handlerAddSoPModal}
                key={"ModalAddService"}
                service={aux}
                servicesAvaiable={servicesAvaiable}
            />
            <AddProductModal
                show={showProductModal}
                handleCloseModal={handleCloseModal}
                handlerAddSoPModal={handlerAddSoPModal}
                key={"ModalAddproduct"}
                product={aux}
                productsAvailable={productsAvaiable}
            />
            <BodyContainer>
                {isLoading &&
                    <WhiteBackground style={{ zIndex: 1000 }} />}
                <div style={{ width: '100%', boxShadow: '' }}>
                    <StyledLabel
                        onClick={() => { navigate('/comandas') }}
                        style={{
                            color: violet_1,
                            cursor: 'pointer',
                            width: '35%',
                            margin: '.4rem 0 1rem 5%',
                        }}
                    >{"< Regresar"}</StyledLabel>
                </div>
                <br></br>

                <CollapseDataClientHeader
                    color="purple_1"
                    turn={collapseDataClient}
                    onClick={() => { setCollapseDataClient(!collapseDataClient) }}
                >{input.name || "Datos del cliente"}</CollapseDataClientHeader>
                {
                    addPoS &&
                    <WhiteBackground onClick={() => setAddPoS(false)} />
                }
                <Main style={{ position: 'relative', height: 'auto' }}>
                    <CollapseDataClient
                        show={!collapseDataClient}
                        display={displayDataClient}
                    >

                        <ClientData
                            documentList={documentList}
                            setDocumentList={setDocumentList}
                            documentType={documentType}
                            setDocumentType={setDocumentType}
                            input={input}
                            setInput={setInput}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            key={"clientData"}
                            formErrors={formErrors}
                            setFormErrors={setFormErrors}
                        />
                        
                    </CollapseDataClient>
                    <br></br>
                    {
                        cart.length > 0 &&
                        cart.map((item, index) => {
                            if (item !== undefined) {
                                if (item.type === "p") {
                                    return <CardProducts
                                        handlerClick={handlerClick}
                                        handlerChangeProducts={handlerChangeProducts}
                                        key={index}
                                        handlerDeleteProduct={handleDelete}
                                        index={index.toString()}
                                        product={item}
                                    ></CardProducts>
                                }
                                return <CardServices
                                    handlerClick={handlerClick}
                                    handlerChangeServices={handlerChangeServices}
                                    key={index}
                                    handlerDeleteService={handleDelete}
                                    index={index.toString()}
                                    service={item}
                                ></CardServices>
                            }
                            return null;
                        })
                    }


                </Main>
                <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', bottom: '15%', right: "5%" }}>
                    <PopOverContent style={{ zIndex: 280 }} show={showPopOver} display={displayPopover} ref={popover}>
                        Agrega aqui tus productos y servicios
                    </PopOverContent>
                    <PlusCircleAnimated
                        turn={addPoS}
                    >

                        <PlusCircleStyle onClick={() => { track("Anadir SoP"); setAddPoS(!addPoS); setCollapseDataClient(true); setShowPopOver(false) }} />
                    </PlusCircleAnimated>
                    {addPoS &&
                        <OptionsStyle onClick={() => setAddPoS(!addPoS)}>
                            <p onClick={() => { track("Añadir servicio"); setShowServiceModal(true) }}>Servicio&nbsp;&nbsp;&nbsp;<BsScissors></BsScissors></p>
                            <p onClick={() => { track("Añadir producto"); setShowProductModal(true) }}>Producto             <GiSpiralBottle></GiSpiralBottle></p>
                        </OptionsStyle>
                    }
                </div>
                <div style={{ position: 'relative', height: '-webkit-fill-available', width: '80%', minHeight: '6rem' }}>
                    <StyledButton
                        ref={saveButtonRef}
                        notEnable={!cart.every(item => (item?.service !== undefined || item === undefined)) || !cart.some(item => item !== undefined)}
                        onClick={handlerSubmit}
                        style={{ width: '100%', position: 'absolute', bottom: '2rem', left: '50%', transform: 'translate(-50%, 0)' }}
                    >Guardar</StyledButton>
                </div>
            </BodyContainer>
        </>
    );

}