export const dark_violet = "#231A59";
export const violet_1 = '#44308C';
export const violet_2 = '#572F90';
export const violet_3 = '#7454A1';

export const magenta_1 = '#7F268C';
export const magenta_2 = '#CA188A';
export const magenta_3 = '#D256A6';

export const white = '#FFFFFF';
export const black = '#000000';
export const black_500 = '#424242';
export const gray_1 = '#F6F6F6';
export const gray_2 = '#E0E0E0';
export const gray_3 = '#BDBDBD';
export const gray_4 = '#C4C4C4';
export const gray_text = '#AAAAAA';
export const soft_gray = '#E7E7E7';

export const green_success = '#00DE66';

export const purple_1 = '#45348E';
export const purple_2 = '#9B4AAE';
export const soft_purple = '#937CF4';

export const dark_purple = '#45348E';   

export const principalDegree = `linear-gradient(111.19deg, ${violet_1} -20.96%, ${soft_purple} 121.51%);`