import fetchTimeout from "../utils/fetchTimeout";
import { IClient, IClientRequest, IDisitrictsRequest, IDistrict, IDocumentTypesRequest } from "../interfaces/cliente";
import { setHeaders, URL_REQUEST } from "./constants";
import { decodeTokenLS } from "../utils/token";

export async function getDocumentList(): Promise<IDocumentTypesRequest> {
    let response: IDocumentTypesRequest;
        let res: any = await fetchTimeout(URL_REQUEST + "/api/v1/general/documentoidentidad", {
            "headers": setHeaders(),
            "body": null,
            "method": "GET",
        });
        response = await res.json();
        if (response.status === "200") {
            return response;
        }
        throw new Error("No se pudo obtener la lista de documentos, por favor revise su conexión a internet y recargue la página");
}
export async function getClient(documentNumber: string, documentType: number): Promise<IClient> {
    let raw = '';
    const decoded = decodeTokenLS();
    if (decoded) {
        const empresaId = decoded.user.empresaId;
        raw = JSON.stringify({
            tipoDocumentoId: documentType,
            nroDocumento: documentNumber,
            empresaId
        });
    }
    let response: IClientRequest;
    let res: any = await fetchTimeout(URL_REQUEST + "/api/v1/admin/cliente/buscarClientePorDocumento", {
        "headers": setHeaders(),
        "body": raw,
        "method": "post",
    });
    response = await res.json();
    if (response.status === "200") {
        return response.data;
    }

    let resApiPeru = await fetchTimeout("https://consulta.api-peru.com/api/dni/"+ documentNumber,{
        "headers": {
            ...setHeaders(),
            "Content-Type":"application/json",
            "Authorization": "Bearer 50ad1fd9e373f36f4cace224a46fde21d9ada69e",
        },
    }, 5000);
    type responseApiPeru = {
        success: boolean,
        data: {
            numero: string,
            apellido_paterno: string,
            nombres: string,
            sexo: string,
            fecha_nacimiento: string,
        }
    }


    let resApiPeruJson: responseApiPeru = await resApiPeru.json();
    if(documentType===2){
        if (resApiPeruJson.success) {
            const { apellido_paterno, nombres, sexo, fecha_nacimiento } = resApiPeruJson.data;
            return {
                nombre: nombres,
                apellido: apellido_paterno,
                sexo: (sexo.toLocaleLowerCase() === "masculino") ? 1 : 2,
                clienteId: null,
                correo: "",
                telefono: "",
                fechaNacimiento: fecha_nacimiento,
            }
        }
    }
    throw new Error(response.message);

}

export async function getDistricts (): Promise<IDistrict[]> {
    let response: IDisitrictsRequest;
    let res: any = await fetchTimeout(URL_REQUEST + "/api/v1/ubigeo/buscarDistritos", {
        "headers": setHeaders(),
        "body": null,
        "method": "GET",
    });
    response = await res.json();
    if (response.status === "200") {
        return response.data;
    }
    throw new Error("No se pudo obtener la lista de distritos, por favor revise su conexión a internet y recargue la página");
}