import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { useState } from 'react';
// import { IServiceHistoryList } from '../../../../interfaces/service-history';
import { useNavigate } from 'react-router-dom';
import { StyledColorContainer, SeeMoreButton, StyledTitleContainer } from '../../../../components/styled/styled';
import './card-historial.css';
import { getDay } from '../../../../utils/getDay';
import { purple_1 } from '../../../../components/styled/constant';
import styled, { keyframes } from 'styled-components';
import useMixpanel from 'hooks/useMixpanel/useMixpanel';

export default function CardHistorial(props: { data: any, myKey: number, color?: string, noEditable?: boolean }) {
    const { track } = useMixpanel();
    let { data } = props;
    const navigation = useNavigate();
    let services = data?.servicios;
    let restServices = services?.slice(1);
    let [seeMore, setSeeMore] = useState(false);

    function handleClickSeeMore() {
        setSeeMore(!seeMore);
    }

    return (
        <StyledColorContainer key={props.myKey} style={{ backgroundColor: props.color || purple_1 }} >
            <div className="card" style={{ width: '-webkit-fill-available' }}>
                <StyledTitleContainer onClick={() => { track("Detalles del servicio desde historial"); navigation(`/comandas/detalle/${data?.id}/0`) }}>
                    <div>
                        <p className='CardTitleHistorial'>{data.nombre}</p>
                        <p className='CardTitleHistorial' style={{marginTop: '5px'}}>{data.nombreProducto || data.nombreServicio}</p>
                    </div>
                    <div>
                        <p className='CardTitleHistorial'>{"#" + data.codigoComanda}</p>
                        <p className='CardTitleHistorial'>{"S/ " + data.precio}</p>
                    </div>
                </StyledTitleContainer>
                <StyledTitleContainer>
                    <div className='ContentItems' >
                        {CardItems(services && services[0].servicio, 0)}
                        <div className={seeMore ? 'ContentItems-more' : 'ContentItems-more hidden'}>
                            {restServices?.map((item: any, index: number) => {
                                return CardItems(item.nombreServicio, index + 1);
                            })}
                        </div>
                        {
                            services?.length > 1 &&
                            <SeeMoreButton style={{ cursor: "pointer" }} onClick={handleClickSeeMore}>
                                <p className='CardContentHistorial'>{seeMore ? 'Ver menos' : 'Ver mas'} </p>
                                <MdOutlineKeyboardArrowDown className={seeMore ? 'rotate' : 'norotate'} />
                            </SeeMoreButton>
                        }
                    </div>
                    <ArrowRight onClick={() => {track("Detalles del servicio desde historial"); navigation(`/comandas/detalle/${data?.id}/${props?.noEditable ? "0" : "1"}`)}} />
                </StyledTitleContainer>
                {data?.diaFinalizacion ?
                    <div>
                        <p className='finalizacion'>{getDay(data?.fechaCreacion) + " / " + (data?.horaFin || "")}</p>
                    </div> :
                    null}
            </div>
        </StyledColorContainer >
    );
}

function CardItems(item: string, index: number) {
    return <p key={index} className='CardContentHistorial'>{item}</p>
}

const shift = keyframes`
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(3%);
  }
  100% {
    transform: translateX(0);
  }
`;

const ArrowRight = styled(MdOutlineKeyboardArrowRight)`
  width: 2rem;
  height: auto;
  margin-right: 5%;
  color: white;
  &:hover {
    cursor: pointer;
    animation: ${shift} 1s ease infinite;
  }
`;