import Login from './pages/login/login';
import { Route, Routes } from 'react-router-dom';
import Comandas from './pages/comandas/comandas';
import Navbar from './components/navbar/navbar';
import AddComanda from './pages/comandas/add/addComanda';
import Detail from './pages/comandas/deatil/detail';
import EditComanda from './pages/comandas/edit/editComanda';
import ServiceQueue from './pages/service-queue/service-queue';
import ServiceHistory from './pages/service-history/service-history';
import Redirect from './components/redirect/redirect';
import { useLogin } from './hooks/login';
import { showLoader, closeLoader } from './utils/loader';
import { useCallback, useEffect, useState } from 'react';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  const { login } = useLogin();

  const handleLogin = useCallback(async () => {
    setIsLoading(true);
    await login();
    setIsLoading(false);
  }, [login]);

  useEffect(() => {
    if (isLoading) {
      showLoader();
    } else {
      closeLoader();
    }
  }, [isLoading]);



  useEffect(() => {
    handleLogin();
  }, [handleLogin])// eslint-disable-line

  

  return (
    <>
      <Routes>
        <Route path="/inicio" element={<Login />} />
        <Route path='/*' element={<Navbar />} >
          <Route path='' element={<Redirect to='/comandas' />} />
          <Route path="comandas" element={<Comandas />} />
          <Route path='comandas/detalle/:id/:editable' element={<Detail />} />
          <Route path='comandas/detalle/:id/:editable/editar' element={<EditComanda />} />
          <Route path="comandas/agregarcomanda" element={<AddComanda />} />
          <Route path="coladeservicios" element={<ServiceQueue />} />
          <Route path="historialdeservicios" element={<ServiceHistory />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
