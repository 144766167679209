import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BsPlusLg } from 'react-icons/bs';
import { getServiceHistoryByDates2 } from 'request/service-history';
import styled, { keyframes } from "styled-components";
import { violet_1 } from "../../../components/styled/constant";
import { showPopup } from "../../../utils/showAlert";
import reportIcon from "../../../assets/report-icon.svg";
import ModalEmailSent from './modalEmailSent';
import { URL_REQUEST } from 'request/constants';

const fadeIn = keyframes`
    from {
        transform: translateY(100vh);
    }
    to {
        transform: translateY(0);
    }
`;

const fadeOut = keyframes`
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(100vh);
    }
`;

const StyledFormGroup = styled("div").attrs((props: { fadeOut: boolean, show: boolean }) => ({ fadeOut: props.fadeOut, show: props.show }))`
    margin-bottom: 1rem;
    flex-direction: column;
    align-items: center;
    width: -webkit-fill-available;
    position: absolute;
    height: 100vh;
    width: 100vw;
    transform: translateY(-100vh);
    animation: ${(props: { fadeOut: boolean }) => props.fadeOut ? fadeOut : fadeIn} 0.5s ease-in-out;
    animation-fill-mode: forwards;
    display: ${(props: { show: boolean }) => props.show ? "flex" : "none"};
    z-index: 500;
    background-color: white;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
`;

const ModalHeader = styled.div`
    margin-top: 1rem;
    color: ${violet_1};
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 5%;
    border-radius: 0.5rem;
    width: -webkit-fill-available;
    font-size: 1rem;
    font-weight: bold;
    justify-content: space-between;
    & label{
        color: ${violet_1};
        margin-left: 1rem;
        text-decoration-line: underline;
        text-decoration-thickness: .2rem;
        text-underline-offset: .2rem;
    }
    & svg {
        font-size: 1.5rem;
        font-weight: bold;
        transform: rotate(45deg);
    }

`;

type Props = {
    show: boolean,
    bgColor?: string,
    setShowModal: (any: any) => void,
    fadeOut: boolean
}

const ModalDateRangePicker = (props: Props): JSX.Element => {

    const today = new Date();
    const { show, setShowModal } = props;
    const [fadeOut, setFadeOut] = useState(props.fadeOut);
    const [firstDateInput, setFirstDateInput] = useState(today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2));
    const [secondDateInput, setSecondDateInput] = useState(today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2));
    const [startDate, setStartDate] = useState(today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2));
    const [endDate, setEndDate] = useState(today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2));
    // const [selectedDate, setSelectedDate] = useState({day: '', month: '', year: ''});
    // const [currentDate, setCurentDate] = useState({day: '', month: '', year: ''});
    const [historial, setHistorial] = useState<any>();
    const [isShowSentEmail, setIsShowSentEmail] = useState<boolean>(false);

    const token = window.localStorage.getItem('token');

    useEffect(() => {
        loadData();
    }, [startDate, endDate]); //eslint-disable-line

    async function loadData() {
        try {
            let data = await getServiceHistoryByDates2(startDate, endDate);
            setHistorial(data);
        } catch (error) {
            showPopup({
                message: 'No se pudo cargar el historial, por favor recarga la página',
                icon: 'error',
            });
        }
    }

    const sendReport = async () => {
        try {
            const data = await axios.post(URL_REQUEST + '/api/v1/admin/comanda/mvp/enviarcorreodetallecomandasfinalizadasfecha', {
                fechaInicio: startDate,
                fechaFin: endDate,
            },
            {
                headers: {
                    'x-access-token': token || '',
                }
            }).then(res => res.data).then(() => setIsShowSentEmail(true));
            return data;
        } catch (e: any) {
            console.log(e.message);
        }
    }

    return (
        <>
            {isShowSentEmail ? <ModalEmailSent setIsShowSentEmail={setIsShowSentEmail}/> : null}
            <StyledFormGroup fadeOut={fadeOut} show={show}>
                <ModalHeader>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <BsPlusLg style={{ cursor: "pointer" }} onClick={() => {setFadeOut(true); setTimeout(() => {setFadeOut(false); setShowModal(false)}, 500)}}/>
                    </div>
                </ModalHeader>
                <span style={{fontSize: '16px', marginBottom: '20px'}}>Generar reporte</span>
                {/* <div 
                    style={{
                        width: '90%',
                        border: '1px solid #937CF4',
                        boxShadow: '4px 4px 4px 1px rgba(0, 0, 0, 0.1)',
                        borderRadius: '4px',
                        height: '320px',
                        marginTop: '20px'
                    }}
                >
                    <div>
                        <img src={leftArrow} alt='back'/>
                        <span>{selectedDate.month}</span>
                        <img src={rightArrow} alt='forward'/>
                    </div>
                </div> */}
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div style={{display: 'flex', width: '100%'}}>
                        <div style={{width: '50%', marginLeft: '25px'}}>
                            <span>Desde</span>
                            <input type='date' value={firstDateInput} onChange={(e: any) => setFirstDateInput(e.target.value.toString())} style={{width: '80%', color: 'black', border: '1px solid black', padding: '5px'}}/>
                        </div>
                        <div style={{width: '50%'}}>
                            <span>Hasta</span>
                            <input type='date' value={secondDateInput} onChange={(e: any) => setSecondDateInput(e.target.value.toString())} style={{width: '80%', color: 'black', border: '1px solid black', padding: '5px'}}/>
                        </div>
                    </div>
                    <button type='button' onClick={() => {setStartDate(firstDateInput); setEndDate(secondDateInput);}}
                        style={{
                        width: '300px',
                        height: '40px',
                        color: 'white',
                        backgroundColor: '#45348E',
                        outline: 'none',
                        fontSize: '20px',
                        border: 'none',
                        borderRadius: '5px',
                        margin: '20px 0'
                    }}>Buscar</button>
                    <div className="containerHistorial" style={{width: '90%'}}>
                        <div className="containerCantidades">
                            <div
                                style={{ margin: "0px 10px 0px 0px" }} className="cardEstadistica">
                                <p className="textoEstadistica">{historial?.cantidadServicios}</p>
                                <p className="textoDetalle">
                                    Total de servicios
                                </p>

                            </div>
                            <div
                                style={{ margin: "0px 0px 0px 0px" }} className="cardEstadistica">
                                <p className="textoEstadistica">{historial?.cantidadProductos}</p>
                                <p className="textoDetalle">
                                    Total de productos
                                </p>

                            </div>
                        </div>
                        <div className="containerTotales">
                            <div
                                style={{ margin: "0px 10px 0px 0px" }} className="cardEstadistica">
                                <p className="textoEstadistica">S/.{historial?.totalVentasServicios}</p>
                                <p className="textoDetalle">
                                    Total por servicios
                                </p>

                            </div>
                            <div
                                style={{ margin: "0px 0px 0px 0px" }} className="cardEstadistica">
                                <p className="textoEstadistica">S/.{historial?.totalVentasProductos}</p>
                                <p className="textoDetalle">
                                    Total por productos
                                </p>
                            </div>
                        </div>
                    </div>
                    <button type='button' onClick={() => {sendReport(); setShowModal(false);}}
                        style={{
                        width: '300px',
                        height: '40px',
                        color: 'white',
                        backgroundColor: '#937CF4',
                        outline: 'none',
                        fontSize: '16px',
                        border: 'none',
                        borderRadius: '5px',
                        margin: '150px 0 20px 0'
                    }}><img src={reportIcon} alt='reporte' style={{height: '15px', marginRight: '5px'}}/>Recibir reporte detallado vía correo</button>
                </div>
            </StyledFormGroup>
        </>
    )
}

export default ModalDateRangePicker;